import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom'

const GroupCard = props => {
    return (
        <>
            <Link to="">
                <div>
                    <div className="flex justify-content-between my-3">
                        <div className="flex gap-2">
                            <img className="group-icons" src="./images/ic_sports.svg" alt="ic_sports" />
                            <div>{props.details.group_name}</div>
                        </div>
                        <div className="flex justify-content-center gap-2">
                            {/* <div className="flex justify-content-start gap-1 group-members">
                                <img src="./images/Vector(9).svg" alt="user" />
                                <div>14378</div>
                            </div> */}
                            <div className="flex justify-content-between">
                                {/* <div className="leave-btn"> */}
                                   {moment(props.details.created_at).format('lll')}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default GroupCard