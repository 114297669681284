import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import './styles/LeftSidePanel.css'
import { useNavigate } from 'react-router-dom';
import ModeContext from '../Context/ModeContext'
import { ThemeContext } from '../Context/ThemeContext';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { LoginContext } from '../Context/LoginContext';
import { useAuth } from '../hooks/useAuth';
import { Log } from 'ethers';
import InfoIcon from '@mui/icons-material/Info';
// import info from "../../public/images/information.svg"
// import { useAuth } from './hooks/useAuth'
const LeftSidePanel = () => {

    const [activeTab, setActiveTab] = useState(window.location.pathname);
    const [selectedSidebar, setSelectedSidebar] = useState(activeTab);
    const [selectedBottombar, setSelectedBottombar] = useState(activeTab);
    // const { status, setStatus } = useContext(LoginContext);
    const navigate = useNavigate();
    const [pathName, setPathName] = useState(window.location.pathname);
    const { logout, user } = useAuth()
    // Control scroll when sidemenu bar is open
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const handleMenuToggle = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
        document.body.classList.toggle('menu-open');
    };
     



    //Sidebar menu option
    const handleSidebar = (e, newValue, r) => {
        e.preventDefault();
        setSelectedSidebar(newValue);
        navigate(r);

    }

    //Bottom menu option
    const handleBottombar = (e, newValue) => {
        e?.preventDefault();
        setSelectedBottombar(newValue);
    }

    //Logout function-----------------------------------
    const handleLogout = () => {
     
        confirmAlert({
            title: 'Confirm to sign out',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        logout()
                        // setStatus(false);
                        // return navigate('/');
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    // Dark mode--------------------------------------
    // const { localTheme, setLocalTheme } = useContext(ModeContext);
    const { themeClass, themeInput, toggleTheme } = useContext(ThemeContext);

    const [darkMode, setDarkMode] = useState(false);

    const [theme, setTheme] = useState(
        localStorage.getItem('theme') || 'Light'
    );
    const toggleThemes = () => {
        if (theme === 'Light') {
            setTheme('Dark');
        } else {
            setTheme('Light');
        }
    };
    // setLocalTheme(theme);
    useEffect(() => {
        localStorage.setItem('theme', theme);
        setDarkMode(theme)
        document.body.className = theme;
    }, [theme]);
    //--------------------------------------
    const { token } = useAuth()
    return (
        <>
            {/* <ModeContext.Provider value={{ darkMode, setDarkMode }}> */}
            <div className="col-lg-3">
                <div className={`container ${theme}`}>
                    <section className='wrapper'>
                        <div className='profile-wrapper mt-3 menu d-flex align-items-center justify-content-center'>
                            <Link to='/dashboard-home'>
                                <img
                                    style={{ width: '200px', borderRadius: '4px', boxShadow: "2px 2px 4px grey", position: "relative" }}
                                    className={theme === "Dark" ? "navIcon logo" : "logo"}
                                    // className={darkMode === 'dark' ? 'navIcon logo' : 'logo'} 
                                    src='/images/logo.svg'
                                    alt='' />
                            </Link>
                        </div>
                        <input onClick={handleMenuToggle} type="checkbox" id="menu-toggle" className="menu-toggle" />
                        <label htmlFor="menu-toggle" className="menu-icon" style={{ boxShadow: "2px 2px 4px grey" }}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                        <div style={{ overflowY: "auto" }} className={theme === 'Dark' ? 'Dark menu-items-container' : 'Light menu-items-container'}>

                            <Link to='/dashboard-home'
                                className="logo-bottom-margin"
                            >
                                <img
                                    style={{ width: '200px', borderRadius: '20px', backgroundColor: "white", position: "fixed" }}
                                    className={theme === "Dark" ? "sidemenu-logo navIcon" : "sidemenu-logo"}
                                    src='/images/logo.svg'
                                    alt='' />
                            </Link>

                            <Link
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-home")}
                                className={selectedSidebar === "/dashboard-home" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}>
                                <div className={selectedSidebar === "home" ? 'navIcon' : ''}>
                                    <img src='/images/home_icon.svg' alt='' />
                                </div>
                                <p className='sidebar'>Home</p>
                            </Link>
                            {/* </Link> */}

                            <Link
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-market")}
                                className={selectedSidebar === "/dashboard-market" || selectedSidebar === "/dashboard-market-detail" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}
                            >
                                <div className={selectedSidebar === "/dashboard-market" ? 'hasNotification' : 'hasNotification'}>
                                    <img src='/images/market_icon.svg' alt='' />
                                    <div className="notificationDot"></div>
                                </div>

                                <p className='sidebar'>Markets</p>
                            </Link>

                            <Link
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-notifications")}
                                className={selectedSidebar === "/dashboard-notifications" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}
                            >
                                <div className={selectedSidebar === "notification" ? 'navIcon' : ''}>
                                    <img src='/images/bell_icon.svg' alt='' />
                                </div>
                                <p className='sidebar'>Notifications</p>
                            </Link>
                            <Link
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-about")}
                                className={selectedSidebar === "/dashboard-about" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}
                            >
                                <div className={selectedSidebar === "aboutus" ? 'navIcon' : ''}>
                                    {/* <img src='/images/icon_help.svg' alt="" /> */}
                                   <InfoIcon/>
                                </div>
                                <p className='sidebar'>About & Help</p>
                            </Link>

                            {/*                           
                            <Link
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-leagues")}
                                className={selectedSidebar === "/dashboard-leagues" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}>
                                <div className={selectedSidebar === "leagues" ? 'navIcon' : ''}>
                                    <img src='/images/troffy_icon.svg' alt='' />
                                </div>
                            </Link> */}
                            <Link
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-group")}
                                className={selectedSidebar === "/dashboard-group" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}>
                                <div className={selectedSidebar === "group" ? 'navIcon' : ''}>
                                    <img src='/images/group_icon.svg' alt='' />
                                </div>
                                <p className='sidebar'>Group</p>
                            </Link>

                            <Link
                                onClick={(e) => [handleSidebar(e, 'auto'), toggleThemes(), toggleTheme()]}
                                className="SidebarInActive menu-items"
                            // className={selectedSidebar === "auto" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}
                            >
                                <div className={selectedSidebar === "auto" ? 'navIcon' : ''}>
                                    <img src='/images/start_icon.svg' alt='' />
                                </div>
                                <p className='sidebar' onClick={toggleTheme}>{theme === "Dark" ? "Light" : "Dark"}</p>
                            </Link>

                            <Link
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-sitemap")}
                                className={selectedSidebar === "/dashboard-sitemap" ? 'SidebarActive menu-items' : 'SidebarInActive menu-items'}
                            >
                                <div className={selectedSidebar === "/dashboard-sitemap" ? 'navIcon' : ''}>
                                    <img src='/images/sitemap_icon.svg' alt='sitemap' />
                                </div>
                                <p className='sidebar'>Sitemap</p>
                            </Link>

                            <Link className='menu-items' >
                                <img src='/images/signout_icon.svg' alt='' />
                                <p className='sidebar' onClick={handleLogout}>Sign Out</p>
                            </Link>
                            
                            <button
                                onClick={(e) => handleSidebar(e, pathName, "/dashboard-create-market")}
                                className={selectedSidebar === "/dashboard-create-market" ? 'Sidebar-Active-Market-Btn create-market-btn menu-items' : 'Sidebar-In-Active-Market-Btn create-market-btn menu-items'}
                            >Create a Market</button>
                            {/* Add more menu items here */}
                        </div>
                    </section>
                </div>

                <nav
                    className={theme === 'Dark' ? 'BottomDark mobile-nav d-flex justify-content-around align-items-center space-around"' : 'mobile-nav d-flex justify-content-around align-items-center space-around"'}
                >
                    <div onClick={(e) => {
                        handleBottombar(e, pathName);
                    }}
                        className={selectedBottombar === '/dashboard-home' ? 'BottombarActive block-icon d-flex justify-content-center align-items-center flex-column' : 'BottombarInActive block-icon d-flex justify-content-center align-items-center flex-column'}
                    >
                        <Link to='/dashboard-home'>
                            <img src="/images/home.svg" alt="" />
                        </Link>
                        <div className="bottom-menu-label">Home</div>
                    </div>
                    <div onClick={(e) => {
                        handleBottombar(e, "pathName");
                    }}
                        className={selectedBottombar === '/dashboard-market' ? 'BottombarActive block-icon d-flex justify-content-center align-items-center flex-column' : 'BottombarInActive block-icon d-flex justify-content-center align-items-center flex-column'}
                    >
                        <Link to='/dashboard-market'>
                            <img src='/images/market_dashboard_icon.svg' alt='' />
                        </Link>
                        <div className="bottom-menu-label">Markets</div>
                    </div>
                    <div onClick={(e) => {
                        handleBottombar(e, pathName);
                    }}
                        className={selectedBottombar === '/dashboard-profile' ? 'BottombarActive block-icon d-flex justify-content-center align-items-center flex-column' : 'BottombarInActive block-icon d-flex justify-content-center align-items-center flex-column'}
                    >
                        <Link to='/dashboard-profile'>
                            <img src="/images/user.svg" alt=""/>
                        </Link>
                        <div className="bottom-menu-label">Profile</div>
                    </div>
                    <div onClick={(e) => {
                        handleBottombar(e, pathName);
                    }}
                        className={selectedBottombar === '/dashboard-notifications' ? 'BottombarActive block-icon d-flex justify-content-center align-items-center flex-column' : 'BottombarInActive block-icon d-flex justify-content-center align-items-center flex-column'}
                    >
                        <Link to='/dashboard-notifications'>
                            <img src="/images/bell.svg" alt="" />
                        </Link>
                        <div className="bottom-menu-label">Notifications</div>
                    </div>
                    {/* <div className="block-icon d-flex justify-content-center align-items-center flex-column">
                        <Link to=''>
                            <div className="navbar-header">
                                <img src='./images/menu.svg' />
                            </div>
                        </Link>
                        <div>Menu</div>
                    </div> */}
                </nav>
            </div >
            {/* </ModeContext.Provider> */}
        </>
    )
}

export default LeftSidePanel