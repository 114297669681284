import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Components/Navbar';
import './styles/About.css'
import { ThemeContext } from '../Context/ThemeContext';

const PrivacyPolicy = () => {
    const { themeClass, themeInput } = useContext(ThemeContext);
    return (
        <>
            {/* <Navbar /> */}
            <div >
                <section className="d-flex align-items-center justify-content-center main-container">
                    <div className="py-2">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col">
                                <div className={`${themeClass === "dark"
                                                            ? "card border-0 bg-black"
                                                            : "card border-0 bg-white"
                                                            }`}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-4">
                                                    <nav className="navbar navbar-expand-md mt-3 mb-1 flex justify-content-start">
                                                        <Link to={-1}>
                                                            <button className='mr-4' style={{ border: 'none', width: 35, height: 35, borderRadius: 30 }}>
                                                                <img src='./images/icon_back.svg' alt="back" />
                                                            </button>
                                                        </Link>
                                                        <h3 className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Privacy Policy</h3>
                                                    </nav>
                                                </div>
                                                <div>
                                                    <div className={`${themeClass === "dark"
                                                        ? "text-light font-weight-bold h4"
                                                        : "text-dark font-weight-bold h4"
                                                        }`}>Privacy Policy for Smarter Bet</div>
                                                    <p className={`${themeClass === "dark"
                                                        ? "primary-color mb-2"
                                                        : "text-dark mb-2"
                                                        }`}>Thank you for visiting Smarter Bet. This privacy policy outlines how we collect, use, and protect any information you provide when using our website.
                                                        Through our website, which facilitates outcome-based trading, we prioritize user privacy by collecting and safeguarding only essential information necessary for the trading process, ensuring transparency, security, and compliance with privacy regulations.</p>
                                                    <p className={`${themeClass === "dark"
                                                        ? "primary-color"
                                                        : "text-dark"
                                                        }`}>By using Smarter Bet, you agree to the terms of this privacy policy. If you have any questions or concerns about this privacy policy, please contact us at our 'Get In Touch' page. </p>
                                                </div>
                                                {/* Rest of the content goes here */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PrivacyPolicy