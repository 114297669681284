import React, { createContext, useState, useEffect } from "react";
import RightSidePanel from "../Components/RightSidePanel";

// Create the ThemeContext
export const ThemeContext = createContext("");

// Create the ThemeProvider component
export const ThemeProvider = ({ children }) => {
  const [themeClass, setThemeClass] = useState("");
  const [themeInput, setThemeInput] = useState("");
  useEffect(() => {
    const localTheme = localStorage.getItem("theme");

    if (localTheme === "Dark") {
      setThemeClass("dark");
    } else {
      setThemeClass("light");
    }
  }, []);

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");

    if (localTheme === "Dark") {
      setThemeInput("input-dark");
    } else {
      setThemeInput("input-light");
    }
  }, []);

  const toggleTheme = () => {
    if (themeClass === "dark") {
      setThemeClass("light");
      setThemeInput("input-light");
      localStorage.setItem("theme", "Light");
    } else {
      setThemeClass("dark");
      setThemeInput("input-dark");
      localStorage.setItem("theme", "Dark");
    }
  };

  const themeContextValue = {
    themeClass,
    themeInput,
    toggleTheme,
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      {children}
      {/* <RightSidePanel /> */}
    </ThemeContext.Provider>
  );
};
