import React from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import './styles/LeaderBoard.css'

const LeaderBoard = () => {


    const { themeClass, themeInput } = useContext(ThemeContext);
    const [active, setActive] = useState("");

    const handleSetActive = (e, newValue) => {
        e?.preventDefault();
        setActive(newValue);
    }


    // Open first tab
    useEffect(() => {
        // Function to simulate a click event on the "Portfolio" tab link
        const openNotificationsTab = () => {
            const defaultOpenLink = document.getElementById("default-open");
            if (defaultOpenLink) {
                defaultOpenLink.click();
            }
        };

        // Call the function to open the "Portfolio" tab when the component mounts
        openNotificationsTab();
    }, []);

    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-start">
                {/* ---------------------Column 1--------------------- */}

                <LeftSidePanel />

                {/* ---------------------Column 2--------------------- */}
                <div className="col-md-9">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mt-5 mb-4">
                                    <nav className="navbar navbar-expand-md mt-3 mb-5 flex justify-content-start">
                                        <Link to='/dashboard-sitemap'>
                                            <button className='mr-4' style={{ border: 'none', width: 35, height: 35, borderRadius: 30 }}>
                                                <img src='./images/icon_back.svg' alt="back" />
                                            </button>
                                        </Link>
                                        <h3>Leader Board</h3>
                                    </nav>
                                    <div className='mt-5'>
                                        <div className="container">
                                            <ul className="nav nav-pills d-flex gap-3">
                                                <li onClick={(e) => handleSetActive(e, "daily")}
                                                    className={active === "daily" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#daily" style={{
                                                        color: themeClass === 'dark' ? 'white' : 'black'
                                                    }}
                                                        id="default-open"
                                                        aria-expanded="true">Daily</a>
                                                </li>
                                                <li onClick={(e) => handleSetActive(e, "weekly")}
                                                    className={active === "weekly" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#weekly" style={{ color: themeClass === 'dark' ? 'white' : 'black' }}>Weekly</a>
                                                </li>
                                                <li onClick={(e) => handleSetActive(e, "monthly")}
                                                    className={active === "monthly" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#monthly" style={{ color: themeClass === 'dark' ? 'white' : 'black' }}>Monthly</a>
                                                </li>
                                                <li onClick={(e) => handleSetActive(e, "alltime")}
                                                    className={active === "alltime" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#alltime" style={{ color: themeClass === 'dark' ? 'white' : 'black' }}>All Time</a>
                                                </li>
                                            </ul>
                                            <hr />

                                            <div className="tab-content mb-5">
                                                <div id="daily" className="tab-pane fade">
                                                    <div className="traders-creator-container flex justify-content-between col-md-12 gap-3">
                                                        <div className="col-md-6">
                                                            <div className="flex gap-3">
                                                                <img className="medal-icon" src="./images/icon_creator.svg" alt="creator" />
                                                                <h4>Top Traders</h4>
                                                            </div>
                                                            <div className="d-flex flex-column gap-3 mt-4">
                                                                <div className="flex justify-content-between gap-2">
                                                                    <div className="flex gap-2">
                                                                        <div>#</div>
                                                                        <div>Name</div>
                                                                    </div>
                                                                    <div>PROFIT</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>1</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Theresa Webb</div>
                                                                    </div>
                                                                    <div>₮905,846</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>2</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Leslie Alexander</div>
                                                                    </div>
                                                                    <div>₮702,214</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>3</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Cameron Williamson</div>
                                                                    </div>
                                                                    <div>₮521,803</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>4</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Bessie Cooper</div>
                                                                    </div>
                                                                    <div>₮525,205</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="flex gap-3">
                                                                <img className="medal-icon" src="./images/icon_creator.svg" alt="creator" />
                                                                <h4>Top Creators</h4>
                                                            </div>
                                                            <div className="d-flex flex-column gap-3 mt-4">
                                                                <div className="flex justify-content-between gap-2">
                                                                    <div className="flex gap-2">
                                                                        <div>#</div>
                                                                        <div>Name</div>
                                                                    </div>
                                                                    <div>PROFIT</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>1</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Theresa Webb</div>
                                                                    </div>
                                                                    <div>₮905,846</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>2</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Leslie Alexander</div>
                                                                    </div>
                                                                    <div>₮702,214</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>3</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Cameron Williamson</div>
                                                                    </div>
                                                                    <div>₮521,803</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>4</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Bessie Cooper</div>
                                                                    </div>
                                                                    <div>₮525,205</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-content-between col-md-12 gap-3 mt-5">
                                                        <div className="col-md-10">
                                                            <div className="flex gap-3">
                                                                <img className="medal-icon" src="./images/icon_creator.svg" alt="creator" />
                                                                <h4>Top Referrers</h4>
                                                            </div>
                                                            <div className="d-flex flex-column gap-3 mt-4">
                                                                <div className="flex justify-content-between gap-2">
                                                                    <div className="flex gap-3">
                                                                        <div>#</div>
                                                                        <div>Name</div>
                                                                        <div>REFERRALS</div>
                                                                    </div>
                                                                    <div>REFERRED PROFITS</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-4">
                                                                        <div>1</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Theresa Webb</div>
                                                                    </div>
                                                                    <div>₮905,846</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>2</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Leslie Alexander</div>
                                                                    </div>
                                                                    <div>₮702,214</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>3</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Cameron Williamson</div>
                                                                    </div>
                                                                    <div>₮521,803</div>
                                                                </div>
                                                                <div className="flex justify-content-between mt-1">
                                                                    <div className="flex gap-3">
                                                                        <div>4</div>
                                                                        <img className="leaders-img" src="./images/Ellipse29(1).svg" alt="user" />
                                                                        <div>Bessie Cooper</div>
                                                                    </div>
                                                                    <div>₮525,205</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="weekly" className="tab-pane fade">
                                                    a2
                                                </div>
                                                <div id="monthly" className="tab-pane fade">
                                                    a3
                                                </div>
                                                <div id="alltime" className="tab-pane fade">
                                                    a4
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaderBoard