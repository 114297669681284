// ** React Imports
import React, { createContext, useEffect, useState } from 'react'

// ** Next Import
// import { useNavigate, useSearchParams } from 'react-router-dom'

// ** Config
import authConfig from '../configs/auth'

// ** Config
// import { ApiEndpoints } from '../network/endpoints';
import localStorageConfig from '../configs/localStorageConfig';
import { axiosInstance } from '../network/config/adapter';
import { ApiEndpoints } from '../network/config/endpoints';
import { useNavigate } from 'react-router';
import FallbackSpinner from '../Components/spinner';
// import FallbackSpinner from '../@core/components/spinner';

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  token: null,
  otpVerifyData: null,
  setToken: () => null,
  setUser: () => null,
  emailMobile: null,
  setEmailMobile: () => null,
  verifyType: null,
  setVerifyType: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve(),
  initVerifyOTP: () => null,
  initAuth: ()=> null,
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)
  const [verifyType, setVerifyType] = useState(defaultProvider.verifyType)
  const [token, setToken] = useState(window.localStorage.getItem(authConfig.storageTokenKeyName))
  const [otpVerifyData, setOtpVerifyData] = useState(null);
  const [availableBalance, setAvailableBalance] = useState()
  const [holdBalance, setHoldBalance] = useState()
  const [emailMobile, setEmailMobile] = useState("")
  const [userid,setuserId] = useState("")
  const [firstLogin,setfirstLogin] = useState("")
  // ** Hooks
  const navigate = useNavigate()
  //   const [searchParams] = useSearchParams()

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
      if (storedToken) {
        setLoading(true)
        axiosInstance
          .get(ApiEndpoints.users.ME, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          })
          .then(response => {
            setuserId (response.data.data.user_id)
           
            setAvailableBalance(Number(response.data.data.availableBalance).toFixed(2));
            setHoldBalance(Number(response.data.data.onHoldBalance).toFixed(2));
            // setUser(response.data.data);
            setLoading(false);
          })
          .catch((error) => {
            localStorage.removeItem(localStorageConfig.userDataKeyName);
            // setUser(null);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
    initAuth();
  }, []);


  const handleLogin = ({ token, user }) => {


    window.localStorage.setItem(authConfig.storageTokenKeyName, token);
    setUser(user);
    // setfirstLogin(user.firstLogin)
    navigate('/dashboard-home')
    // const redirectUrl = searchParams.get('redirect')
    // navigate(redirectUrl || "/dashboard-home");
  }

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    window.localStorage.removeItem(localStorageConfig.userDataKeyName)
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    navigate('/')
  }

  const initVerifyOTP = (data) => {
    setOtpVerifyData(data)
    navigate("/verify-otp");
  }
  const fetchUserdata = async () => {
    setIsInitialized(true)
    const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
    if (storedToken) {
      axiosInstance
        .get(ApiEndpoints.users.ME, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        })
        .then(response => {
          setuserId (response.data.data.user_id)
          setAvailableBalance(Number(response.data.data.availableBalance).toFixed(2));
          setHoldBalance(Number(response.data.data.onHoldBalance).toFixed(2));
          setUser(response?.data?.data);
          // setfirstLogin(response.data.data.firstLogin)
        })
        .then((response) => {
        })
        .catch((error) => {
          localStorage.removeItem(localStorageConfig.userDataKeyName);
          setUser(null);
        });
    } else {
     
    }
  }
  const handleRegister = async (data) => {

    // if (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(emailMobile)) {
    //   try {
    //     const emailRegistrationResponse = await axiosInstance.post(ApiEndpoints.auth.REGISTER_WITH_EMAIL, params);
    //     let storageToken = emailRegistrationResponse.data.token;
    //     setToken(emailRegistrationResponse.data.token)
    //     setEmailMobile(emailRegistrationResponse.data.user.email)

    //     localStorage.setItem(authConfig.storageTokenKeyName, storageToken)

    //     setVerifyType(emailRegistrationResponse.data.verifyType)
    //     successCallback({
    //       verifyType: emailRegistrationResponse.data.verifyType,
    //       token: emailRegistrationResponse.data.token,
    //     })
    //     // onVerify(verifyType)
    //   } catch (error) {
    //     console.error('Email Registration Error:', error);
    //     errorCallback(error.message)
    //   }
    // } else {
    //   try {
    //     const phoneRegistrationResponse = await axiosInstance.post(ApiEndpoints.auth.REGISTER_WITH_MOBILE, params);
    //     let storageToken = phoneRegistrationResponse.data.token;
    //     setVerifyType(phoneRegistrationResponse.data.verifyType)
    //     setToken(phoneRegistrationResponse.data.token)
    //     setEmailMobile(phoneRegistrationResponse.data.user.mobileNumber)

    //     localStorage.setItem(authConfig.storageTokenKeyName, storageToken)
    //     setVerifyType(phoneRegistrationResponse.data.verifyType)
    //     successCallback({
    //       verifyType: phoneRegistrationResponse.data.verifyType,
    //       token: phoneRegistrationResponse.data.token,
    //     })
    //     // onVerify(verifyType)
    //   } catch (error) {
    //     console.error('Phone Registration Error:', error);
    //     errorCallback(error.message)
    //   }
    // }
  }

  const values = {
    userid,
    holdBalance,
    firstLogin,
    availableBalance,
    user,
    otpVerifyData,
    loading,
    setUser,
    setLoading,
    emailMobile,
    setEmailMobile,
    verifyType,
    setVerifyType,
    token,
    setToken,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
    initVerifyOTP: initVerifyOTP,
    fetchUserdata:fetchUserdata
  }

  return <AuthContext.Provider value={values}>
    {loading ? <FallbackSpinner /> : children}
  </AuthContext.Provider>
}

export { AuthContext, AuthProvider }
