import { useEffect } from "react";
import { Link } from "react-router-dom"

function HowToCreateMarket() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container" style={{ paddingBlock: "20px", width: "100%", display: 'flex', flexDirection: 'column' }}>
            <Link to={-1}>
                <button className="mr-4" style={{ border: "none", width: "35px", height: "35px", borderRadius: "30px" }}>
                    <img src="./images/icon_back.svg" alt="back" />
                </button>
            </Link>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <h1>How to Create Market</h1>
                <p>Follow the steps in this video to create market easily.</p>
            </div>
            <div style={{ display: "flex", height: "80vh" }}>
                <iframe
                    src="../videos/How_to_create_a_market.mp4"
                    style={{ flex: 1, width: '100%', height: '100%' }}
                    // frameBorder="0"
                    allowFullScreen
                    title="How to Create Market"
                />
            </div>
            <div style={{ padding: '20px', display: "flex", flexDirection: "column", gap: 20 }}>
            <p>0:00: In this video, I'm going to show you how to create your own market step by step so that other people can trade on the outcome of your market.</p>
                <p>0:07: Hi, I'm Nick from Smarter.</p>
                <p>0:09: Bet this video will guide you through the process to set up your wallet so you can start trading on the outcome of events.</p>
                <p>0:15: Let's get started.</p>
                <p>0:17: The first step in creating a market is to click the create a market button on the home page.</p>
                <p>0:21: This will open the create a market page.</p>
                <p>0:24: You will now be prompted to write the market question that people will trade on.</p>
                <p>0:28: For example, I chose, will Donald Trump win the US elections 2024?</p>
                <p>0:33: Ensure your question can be answered with a simple yes or no.</p>
                <p>0:37: Once that's done, write a description for the market and add an image by clicking on the choose file button.</p>
                <p>0:42: This will ensure your market has a unique image, making it easier for traders to understand what your market is about to finish.</p>
                <p>0:49: Make sure to add the market to the appropriate group based on its topic.</p>
                <p>0:54: For example, since this market is about elections, so I added it to the election group lastly set the date for when the market will close and the outcome will be determined.</p>
                <p>1:04: In this example, I chose November 5th late in the day since that's when the US elections are and when we will know the outcome.</p>
                <p>1:11: Now that you filled in all the required information, you are ready to submit your market to the smarter bet platform.</p>
                <p>1:17: Simply press the create market button below.</p>
                <p>1:20: This will cost you $1 and your market will be ready to be viewed and traded on the smarter bet platform there.</p>
                <p>1:26: You have it in just a few simple steps.</p>
                <p>1:28: You will now have created a market that anyone can now trade on.</p>
                <p>1:31: If you have any concerns or questions, feel free to reach out to us on the contact us page and I would be happy to address it.</p>
                <p>1:39: Thanks for watching.</p>
            </div>
        </div>

    )
}
export default HowToCreateMarket