import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './styles/PasswordResetSuccess.css'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useState } from 'react'
import showPwdImg from './show-password.svg'
import hidePwdImg from './hide-password.svg'
import Navbar from './Components/Navbar'
import { axiosInstance } from './network/config/adapter'
import { ApiEndpoints } from './network/config/endpoints'
import { useAuth } from './hooks/useAuth'


const PasswordResetSuccess = () => {
    const auth = useAuth()
    const [isRevealPwd, setIsRevealPwd] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Create a data object with the form values
        const data = {
            email: email,
            password: password,
        };

        try {
            // Make a POST request using Axios
            const response = await axiosInstance.post(ApiEndpoints.auth.SIGN_IN, data);
            auth.login(response.data)
        } catch (error) {
            const notify = () => {
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            };
            notify();
        }
    };

    return (

        <>
            <div style={{ minHeight: '100vh' }}>
            <Navbar />
                <section className="d-flex align-items-center justify-content-center main-container">
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col">
                                <div className="card card-registration my-4 border-0">
                                    <div className="row g-0">
                                        <div className='row col-xl-6 d-flex justify-content-center align-items-center'>
                                            <img src="images/password_success.svg"
                                                alt="Sample photo" className="password-success-img img-fluid center-block col-xl-6 px-5" />
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <h5 style={{ color: '#25CF16', fontSize: '28px' }}>SUCCESS!</h5>
                                                <p style={{ fontSize: '24px' }}>Password Reset Successfully</p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6" style={{ boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.12)' }}>
                                            <form onSubmit={handleSubmit}>
                                                <div className="card-body mx-5 text-black">
                                                    <h3 className="mb-2 label signin-label">Sign In</h3>
                                                    <p className="mb-3 text-dark">Please enter your credential to login.</p>

                                                    <div className="form-outline mb-3">
                                                        <label className="label-color" for="form3Example97">Email</label>
                                                        <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                            type="email"
                                                            id="form3Example97"
                                                            className="form-control form-control-sm shadow-none"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="form-outline mb-2">
                                                        <label className="label-color" for="form3Example97">Password</label>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                                type={isRevealPwd ? "password" : "text"}
                                                                id="form3Example97"
                                                                className="form-control form-control-sm shadow-none"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                required
                                                            />
                                                            <img className='pwd-container-img'
                                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                                alt=''
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-outline mb-3 d-flex justify-content-end align-items-end">
                                                <Link to='/forgot-password'><p><a href='#' className='forgot-text'>Forgot password?</a></p></Link>
                                            </div> */}

                                                    <div className='d-flex flex-column'>
                                                        <button type="submit" className="col-xl-12 btn btn-lg" style={{ backgroundColor: '#069514', border: 'none', color: '#fff', borderRadius: 30 }}>Login</button>

                                                        <h5 className='py-2' style={{ fontSize: '16px', textAlign: 'center', margin: '8px' }}>Or</h5>

                                                        <button type="submit" className="col-xl-12 btn btn-lg" style={{ border: '1px solid black', borderRadius: 30 }}> <img src='./images/google.svg' style={{ position: 'relative', left: '-20px' }} alt='' />Login with Google</button>
                                                    </div>

                                                    <div className="d-flex justify-content-center align-items-center mt-3 ">
                                                        <Link to='/signup'><p className='already'>Don't have an account?<a href='#' style={{ color: '#069514', fontWeight: 700, textDecoration: 'none', fontFamily: 'Ubuntu' }}> Sign Up </a></p></Link>
                                                    </div>
                                                    <ToastContainer />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PasswordResetSuccess