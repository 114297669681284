import React, { useContext } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import { ThemeContext } from '../Context/ThemeContext';

const Navbar = () => {
    const { themeClass } = useContext(ThemeContext);
    const [theme, setTheme] = useState(
        localStorage.getItem('theme') || 'Light'
    );
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <button className="ml-2 navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className='profile-wrapper mt-3 menu d-flex align-items-center justify-content-center'>
                        <Link to='#'>
                            <img
                                style={{ width: '200px', borderRadius: '4px', boxShadow: "2px 2px 4px grey", position: "relative" }}
                                className={`${themeClass} `}
                                // className={darkMode === 'dark' ? 'navIcon logo' : 'logo'} 
                                src='/images/logo.svg'
                                alt='' />
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <div className="nav-menu nav-links my-2">
                            <Link to="/" className='hover-link'>HOME</Link>
                            <Link to="/about-us" className='hover-link'>ABOUT US</Link>
                            <Link to="/signin" className='hover-link'>SIGN IN</Link>
                            <Link to="/signup" className='signup-button'>SIGN UP</Link>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar