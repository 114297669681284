import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import { Link } from 'react-router-dom'
import "./styles/RightSidePanel.css"
import Metamask from '../Components/Metamask'
import axios from 'axios'
import { useAuth } from '../hooks/useAuth.js'
import { axiosInstance } from '../network/config/adapter.js'
import { ApiEndpoints } from '../network/config/endpoints.js'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify'


const RightSidePanel = () => {
    // const { localTheme, setLocalTheme } = useContext(ModeContext);
    // const [themeClass, setThemeClass] = useState('');
    // useEffect(() => {
    //     if (localTheme == 'Dark') {
    //         setThemeClass('dark');
    //     } else {
    //         setThemeClass('light');
    //     }
    // });

    const { availableBalance, holdBalance } = useAuth()
    const { themeClass, themeInput, toggleTheme } = useContext(ThemeContext);
    // Portfolio Modal box
    const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
    // const [copied, setCopied] = React.useState(false);
    const [value, setValue] = React.useState("http://odds-react-web.s3-website.eu-west-2.amazonaws.com");

    const handleOpenPortfolioModal = () => {
        setIsPortfolioModalOpen(true);
    };

    const handleClosePortfolioModal = () => {
        setIsPortfolioModalOpen(false);
    };

    // Streak Modal box
    const [isStreakModalOpen, setIsStreakModalOpen] = useState(false);

    const handleOpenStreakModal = () => {
        setIsStreakModalOpen(true);
    };

    const handleCloseStreakModal = () => {
        setIsStreakModalOpen(false);
    };


    const addressText = '0x9DC0c073f1De57187822D8590B2E2c9bcf1F1Da0';
    const [copied, setCopied] = useState(false);
    const notify = () => {
        toast.success("Copied to Clipboard!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };
    const handleCopy = () => {
        setCopied(true);
        notify();
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <>
            <div className="d-flex flex-column gap-2">
                {/* Metamask */}
                <div className="right-side gap-3">
                    <div className="right-side-wrapper d-flex justify-content-center align-items-center">
                        <Metamask />
                    </div>

                    {/* Wallet Amount */}
                    <div className='right-side-wrapper d-flex align-items-center gap-1'>
                        <img src='./images/icon_crown.svg' style={{ width: 28, height: 28 }} alt='' />
                        <div>
                            <p className={`${themeClass} `} style={{ display: "flex" }}>Wallet Amount</p>
                            <p className={`${themeClass} `}>${availableBalance}</p>
                        </div>
                    </div>
                </div>

                <div className="right-side gap-3 justify-content-center justify-content-sm-between">
                    <div className='right-side-wrapper d-flex align-items-center gap-1'>
                        <img src='./images/icon_crown.svg' style={{ width: 28, height: 28 }} alt='' />
                        <div>
                            <p className={`${themeClass} `} style={{ display: "flex" }}>Hold Amount</p>
                            <p className={`${themeClass} `}>${holdBalance}</p>
                        </div>
                    </div>

                    <div className='right-side-wrapper d-flex justify-content-center align-items-center gap-1'>

                        {/* <div><img className="ml-4" id="copy-img" src="./images/copy.svg" alt="copy" style={{ width: '20px', height: '20px' }} /></div> */}
                        {/* <div className="right-side-wrapper d-flex justify-content-center align-items-center"> */}
                        <CopyToClipboard onCopy={handleCopy} text={addressText}>
                            <img
                                id="copy-img"
                                src="./images/copy.svg"
                                alt="copy"
                                style={{ width: '20px', height: '20px', cursor: 'pointer', marginLeft: "6px" }}
                            />
                        </CopyToClipboard>
                        {/* </div> */}
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end",marginLeft:"2px" }}>
                            <p className={`${themeClass} `} >Deposit Address</p>
                            <p>{addressText.length > 12 ? `${addressText.slice(0, 12)}...` : addressText}</p>
                        </div>
                    </div>
                </div>

                {/* <div class="container text-center">
                <div class="row">
                    <div class="col-6 col-lg-12 d-flex justify-content-start">wallet</div>
                    <div class="col-6 col-lg-12 d-flex justify-content-end">paisa</div>
                </div>
            </div> */}

            </div>

            
        </>
    )
}

export default RightSidePanel