import React from 'react'
import './styles/NotFound.css'
import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

const NotFound = () => {
    const { token } = useAuth()

    return (
        <>
            <section className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="col-sm-12 col-sm-offset-1  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>

                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        Look like you're lost
                                    </h3>

                                    <p>the page you are looking for not avaible!</p>

                                    {/* <a href="http://localhost:3000/dashboard-home" className="link_404">Go to Home</a> */}
                                    {token === null ? <> <Link to="/signin">
                                        <a href="" className="link_404">Go to Home</a>
                                    </Link></>:<> <Link to="/dashboard-home">
                                        <a href="" className="link_404">Go to Home</a>
                                    </Link></>}
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFound