import React from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import './styles/Group.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import GroupCard from '../Components/GroupCard'
import { axiosInstance } from '../network/config/adapter'
import { ApiEndpoints } from '../network/config/endpoints'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'

const Group = () => {

    const { themeClass, themeInput } = useContext(ThemeContext);
    const [active, setActive] = useState("");
    const [groupName, setGroupName] = useState("");
    const [groupAbout, setGroupAbout] = useState("");
    const [groupInfo, setGroupInfo] = useState([]);
    const [groupCommunity, setTopCommunities] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [groups,setGroup] = useState([]);
    const [value,setValue] = useState('');
    const navigate = useNavigate();


    // Create Group^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const handleCreateGroup = async (e) => {
        e?.preventDefault();

        const payload = {
            group_name: groupName,
            privacy: "Curated",
            about: groupAbout
        };

        try {
            const response = await axiosInstance.post(ApiEndpoints.group.CREATE_GROUP, payload);
            toast.success(response.message)
            // navigate('/dashboard-group');
            window.location.reload();
        } catch (error) {
            toast.error(error?.response?.data?.error || "Something went wrong!");
        }
        
    }

    // Fetch group information
    useEffect(() => {
        async function getGroup(searchValue) {
            try {
                const response = await axiosInstance.get(ApiEndpoints.group.GROUP_DETAIL,{ params: { search: searchValue } });
                setGroupInfo(response.data);
                if(searchValue === ""){
                    setTopCommunities(response.data.slice(0,5))
                }
                
                // setFilterGroup(response.data);
            } catch (error) {
                return error;  
            }
        }
        getGroup(searchValue);
    }, [searchValue]);

    useEffect(() => {
        async function getGroup(searchValue) {
            try {
                const response = await axiosInstance.get(ApiEndpoints.group.GET_GROUP,{ params: { search: value } });
                setGroup(response.data);
               
                // setFilterGroup(response.data);
            } catch (error) {
                return error;  
            }
        }
        getGroup(value);
    }, [value]);
    const handleSetActive = (e, newValue) => {
        e?.preventDefault();
        setActive(newValue);
    }

    // Open first tab
    useEffect(() => {
        // Function to simulate a click event on the "Portfolio" tab link
        const openNotificationsTab = () => {
            const defaultOpenLink = document.getElementById("default-open");
            if (defaultOpenLink) {
                defaultOpenLink.click();
            }
        };

        // Call the function to open the "Portfolio" tab when the component mounts
        openNotificationsTab();
    }, []);


    // Create Group Modal box
    const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);

    const handleOpenCreateGroupModal = () => {
        setIsCreateGroupModalOpen(true);
    };

    const handleCloseCreateGroupModal = () => {
        setIsCreateGroupModalOpen(false);
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };
    const handleInputChangeSearch = (event) => {
        setValue(event.target.value);
    };
    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-start">
                {/* ---------------------Column 1--------------------- */}

                <LeftSidePanel />

                {/* ---------------------Column 2--------------------- */}
                <div className="col-md-9">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mt-5 mb-4">
                                    <div className="flex justify-content-between pr-1">
                                        <h3>Group</h3>
                                        <div className="new-group-btn" onClick={handleOpenCreateGroupModal}>New Group</div>
                                    </div>
                                    {isCreateGroupModalOpen && (
                                        <div className="modal">
                                            <div className={`${themeClass === "dark"
                                                ? "text-light modal-content bg-dark"
                                                : "modal-content"}`}>
                                                <span className="modal-close" onClick={handleCloseCreateGroupModal}>
                                                    <img src="./images/cross.png" alt="close" style={{ width: "40px" }} />
                                                </span>
                                                <div className="flex">
                                                    <div className="flex gap-2 col-md-4 p-0 ">
                                                        <div>
                                                            <h4>Create Group</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <div>Group Name:</div>
                                                    <div className="col-md-8 mt-2">
                                                        <input type="text"
                                                            className={`${themeInput} form-control mb-4`}
                                                            placeholder="Enter Group Name"
                                                            value={groupName}
                                                            maxLength={15}
                                                            onChange={(e) => setGroupName(e.target.value)}
                                                            required />
                                                    </div>
                                                    <div>About:</div>
                                                    <div className="col-md-8 mt-2">
                                                        <input type="text"
                                                            className={`${themeInput} form-control mb-4`}
                                                            placeholder="Enter Group Name"
                                                            value={groupAbout}
                                                            maxLength={10}
                                                            onChange={(e) => setGroupAbout(e.target.value)}
                                                            required />
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <div className="flex justify-content-between m-2">
                                                        <div className="flex">
                                                            <button className="create-group-btn" type="btn" onClick={() => handleCreateGroup()}>Create</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* Create group Modal end*/}
                                    <div className='mt-5'>
                                        <div className="container">
                                            <ul className="nav nav-pills d-flex gap-3">
                                            <li onClick={(e) => handleSetActive(e, "discover")}
                                                    className={active === "discover" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#discover" style={{ color: themeClass === 'dark' ? 'white' : 'black' }}  id="default-open">Discover</a>
                                                </li>
                                                <li onClick={(e) => handleSetActive(e, "yourGroups")}
                                                    className={active === "yourGroups" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#yourGroups" style={{
                                                        color: themeClass === 'dark' ? 'white' : 'black'
                                                    }}
                                                       
                                                        aria-expanded="true">Your Groups</a>
                                                </li>
                                               
                                            </ul>
                                            <hr />

                                            {/* Your Groups section  */}
                                            <div className="tab-content mb-5">
                                                <div id="yourGroups" className="tab-pane fade">

                                                    <div className='container mb-4 col-md-12 d-flex justify-content-start align-items-center gap-3' style={{ padding: '0px' }}>
                                                        <div className='col-md-12' style={{ padding: '0px' }}>
                                                            <div className="search-bar form-outline d-flex align-items-center" style={{ border: '1px solid #d2d2d2', borderRadius: '6px' }}>
                                                                <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search"  onChange={handleInputChangeSearch} aria-label="Search" />
                                                                <img className="px-2" src='./images/icon_search.svg' alt="search" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        groups.map((groupItem) => (
                                                            <GroupCard key={groupItem._id} details={groupItem}/>
                                                        ))
                                                    }
                                                </div>

                                                {/* Discover section */}
                                                <div id="discover" className="tab-pane fade">

                                                    {/* Top Communities */}
                                                    <h4 style={{ color: "#069514", margin: "15px 0px" }}>Top Communities</h4>

                                                    <div>
                                                    {groupCommunity?.map((community) => {
                                                        return (
                                                            <div key={community._id} className="col-md-12 discover-card flex justify-content-between my-2">
                                                                <div className="discover-icon-wrapper flex gap-2">
                                                                    <img className="discover-icon" src="./images/Logo1.svg" alt="remote" />
                                                                    <h6 className="m-0">{community.group_name}</h6>
                                                                </div>
                                                                <div className="flex justify-content-end">
                                                                    {/* <div className="discover-description">gamers betting on "stocks" for streamers</div>
                                                                     */}
                                                                       {moment(community.created_at).format('lll')}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}

                                                        {/* <div className="col-md-12 discover-card flex justify-content-between my-2">
                                                            <div className="discover-icon-wrapper flex gap-2">
                                                                <img className="discover-icon" src="./images/Logo1.svg" alt="remote" />
                                                                <h6 className="m-0">Destiny.gg</h6>
                                                            </div>
                                                            <div className="flex justify-content-end">
                                                                <div className="discover-description">gamers betting on "stocks" for streamers</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 discover-card flex justify-content-between my-2">
                                                            <div className="discover-icon-wrapper flex gap-2">
                                                                <img className="discover-icon" src="./images/ic_remote.svg" alt="remote" />
                                                                <h6 className="m-0">EA & Rationality</h6>
                                                            </div>
                                                            <div className="flex justify-content-end">
                                                                <div className="discover-description">nerds with a math-based life philosophy</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 discover-card flex justify-content-between my-2">
                                                            <div className="discover-icon-wrapper flex gap-2">
                                                                <img className="discover-icon" src="./images/ic_internet.svg" alt="remote" />
                                                                <h6 className="m-0">AI</h6>
                                                            </div>
                                                            <div className="flex justify-content-end">
                                                                <div className="discover-description">robots taking over, soon-ish</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 discover-card flex justify-content-between my-2">
                                                            <div className="discover-icon-wrapper flex gap-2">
                                                                <img className="discover-icon" src="./images/icon_dice.svg" alt="remote" />
                                                                <h6 className="m-0">Fun</h6>
                                                            </div>
                                                            <div className="flex justify-content-end">
                                                                <div className="discover-description">degens gambling on manifold</div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    {/* Discover Groups */}
                                                    <h4 style={{ color: "#069514", margin: "30px 0px" }}>Discover Groups</h4>

                                                    <div className='container mb-4 col-md-12 d-flex justify-content-start align-items-center gap-3' style={{ padding: '0px' }}>
                                                        <div className='col-md-12' style={{ padding: '0px' }}>
                                                            <div className="search-bar form-outline d-flex align-items-center" style={{ border: '1px solid #d2d2d2', borderRadius: '6px' }} onChange={handleInputChange}>
                                                                <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search" aria-label="Search" />
                                                                <img className="px-2" src='./images/icon_search.svg' alt="search" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {groupInfo?.map((groupItem) => {
                                                            return (<>
                                                                <div className="flex justify-content-between my-3" key={groupItem._id}>
                                                                    <div className="flex gap-2">
                                                                        <img className="group-icons" src="./images/ic_sports.svg" alt="ic_sports" />
                                                                        <div>{groupItem.group_name}</div>
                                                                    </div>
                                                                    <div className="flex justify-content-center gap-2">
                                                                        {/* <div className="flex justify-content-start gap-1 group-members">
                                                                            <img src="./images/Vector(9).svg" alt="user" />
                                                                            <div>14378</div>
                                                                        </div> */}
                                                                        <div className="flex justify-content-between">
                                                                        {moment(groupItem?.created_at).format('lll')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)
                                                        })}
                                                
                                                        {/* <div className="flex justify-content-between my-3">
                                                            <div className="flex gap-2">
                                                                <img className="group-icons" src="./images/ic_politics.svg" alt="Politics" />
                                                                <div>US Politics</div>
                                                            </div>
                                                            <div className="flex justify-content-center gap-2">
                                                                <div className="flex justify-content-start gap-1 group-members">
                                                                    <img src="./images/Vector(9).svg" alt="user" />
                                                                    <div>2207</div>
                                                                </div>
                                                                <div className="flex justify-content-between">
                                                                    <div className="join-btn">
                                                                        <img src="./images/icon_plus(1).svg" alt="join group" />
                                                                        Join
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between my-3">
                                                            <div className="flex gap-2">
                                                                <img className="group-icons" src="./images/ic_gaming.svg" alt="Gaming" />
                                                                <div>Gaming</div>
                                                            </div>
                                                            <div className="flex justify-content-center gap-2">
                                                                <div className="flex justify-content-start gap-1 group-members">
                                                                    <img src="./images/Vector(9).svg" alt="user" />
                                                                    <div>207</div>
                                                                </div>
                                                                <div className="flex justify-content-between">
                                                                    <div className="join-btn">
                                                                        <img src="./images/icon_plus(1).svg" alt="join group" />
                                                                        Join
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between my-3">
                                                            <div className="flex gap-2">
                                                                <img className="group-icons" src="./images/ic_internet.svg" alt="Internet" />
                                                                <div>Internet</div>
                                                            </div>
                                                            <div className="flex justify-content-center gap-2">
                                                                <div className="flex justify-content-start gap-1 group-members">
                                                                    <img src="./images/Vector(9).svg" alt="user" />
                                                                    <div>1801</div>
                                                                </div>
                                                                <div className="flex justify-content-between">
                                                                    <div className="join-btn">
                                                                        <img src="./images/icon_plus(1).svg" alt="join group" />
                                                                        Join
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <ToastContainer />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Group