import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ApiEndpoints } from '../network/config/endpoints';
import { axiosInstance } from '../network/config/adapter';
import FallbackSpinner from './spinner';
import { useAuth } from '../hooks/useAuth.js'
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import moment from 'moment';
import { Avatar } from '@mui/material';
const DashboardHomeMarketComponent = (props) => {
    const { availableBalance, fetchUserdata, user } = useAuth()
    const { themeClass, themeInput } = useContext(ThemeContext);
    const [modalTheme, setModalTheme] = useState("green-theme");
    const [modalBtnColor, setModalBtnColor] = useState("modalBtnGreen");
    const [rangePrice, setRangePrice] = useState(1);
    const [modalPrice, setModalPrice] = useState(1);
    const [quantity, setquantity] = useState(0);
    const [answer, setAnswer] = useState("yes");
    const [betLoading, setBetLoading] = useState(false)
    const [showModal, setShowModal] = useState(false);

    const { getMarket } = props;
    const baseURL = "http://192.168.0.188:3000";

    // Modal box
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
            setIsModalOpen(true);
       
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        
    };


    // const FirstBetApi = () => {
    //     axiosInstance.patch(ApiEndpoints.auth.FirstBet)
       
    // }

    const handleClose = () => {
        setShowModal(false)
        setIsModalOpen(true);
        // FirstBetApi();
        // fetchUserdata();
    };

    // --------------------Only Numbers in modalbox----------------------
    const handleKeyPress = (event) => {
        const isValidInput = /^[0-9]+$/.test(event.key);

        if (!isValidInput) {
            event.preventDefault();
        }
    };
    const handleChangeModal = (event) => {
        setModalPrice(Number(event.target.value));
    };
    const handleClick = (type) => {
        if (type === 'increase') {
            setModalPrice(modalPrice + 1); // Increase the modal price by 1
            setRangePrice(modalPrice + 1)
        } else if (type === 'decrease' && modalPrice > 1) {
            setModalPrice(modalPrice - 1); // Decrease the modal price by 1, but keep it positive
            setRangePrice(modalPrice - 1)
        }
    }
    // -----------------Set modal button color----------------------
    useEffect(() => {
        if (modalTheme === "red-theme")
            setModalBtnColor("modalBtnRed");
        else
            setModalBtnColor("modalBtnGreen");
    }, [modalTheme])

    // ^^^^^^^^^^^^^^^PLACE BET^^^^^^^^^^^^^^^^^^^^^^^^^^
    // const handleCreateClient = async (e) => {
    //     e?.preventDefault();

    //     const payload = {
    //         type: "person",
    //         email: email,
    //         // mobile: `+${countryCode} ${complyCubeMobile}`,
    //         // telephone: `+${countryCode} ${complyCubePhone}`,
    //         personDetails: {
    //             firstName: kycFirstName,
    //             lastName: kycLastName,
    //             dob: moment(selectedDate).format('YYYY-MM-DD'),
    //             nationality: selectedCountry
    //         }
    //     };

    //     try {
    //         const URL = `${baseURL}/api/create-client`;
    //         const response = await axios.post(URL, payload, {
    //             headers: {
    //                 'Authorization': `Bearer ${localToken}`,
    //                 'Content-Type': 'application/json',
    //             }
    //         });


    //     } catch (error) {

    //         // Handle the error (e.g., show an error message)
    //     }
    // };
    const betPlaced = async () => {
        const payload = {
            question: props.market._id,
            amount: rangePrice,
            quantity: rangePrice,
            unitPrice: rangePrice,
            answer: answer
        }
        setBetLoading(true);
        axiosInstance
            .post(ApiEndpoints.betting.BET, payload)
            .then((response) => {
                getMarket();
                const notify = () => {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
                setIsModalOpen(false);
                fetchUserdata();
            })

            .catch((error) => {
                const notify = () => {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
            })
            .finally(() => {
                setBetLoading(false);
            })
    }

    

    return (
        <>
            <section className='market-hover'>
                <div className='feed-image-section mb-4'>
                    <Link to={props.market.createdBy == null ? "" : `/dashboard-market-detail/${props.market._id}`}>
                        <div className='flex justify-content-between p-2'>
                            <Link to={props.market.createdBy == null ? "" : `/dashboard-other-profile/${props.market.createdBy._id}`}>
                                <div className='flex justify-content-center '>
                                    <Avatar
                                        className='feed-profile-image'
                                        sx={{ marginBottom: "5px" }}
                                        src={props.market.createdBy?.profile_picture || "./images/photo.png"} alt=''
                                    />
                                    {/* <img className='feed-profile-image' src={props.market.createdBy?.profile_picture || "./images/photo.png"} alt='' /> */}
                                    <p className='text-capitalize'>{props.market.createdBy === null ? 'User' : props.market.createdBy?.firstName + " " + props.market.createdBy?.lastName}</p>
                                </div>
                            </Link>
                            <div className='flex justify-content-center flex-column gap-2 p-2'>
                                <div className="flex justify-content-center gap-2">
                                    <img width="15px" src="/images/icon_clock.svg" alt="user" />
                                    {moment(props.market.date).format('lll')}
                                </div>

                                <div className='like-icon-container flex gap-2'>
                                    {/* <img className='clickable-icon' src='./images/icon_like.svg' alt="" />
                                    <p>2</p>
                                    <img className='clickable-icon' src='./images/Group(5).svg' alt="" />
                                    <p>12</p> */}
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={props.market.createdBy == null ? "" : `/dashboard-market-detail/${props.market._id}`}>
                        <div className='p-2' style={{ wordBreak: "break-word" }}>{props.market.question}</div>
                    </Link>
                    <Link to={props.market.createdBy == null ? "" : `/dashboard-market-detail/${props.market._id}`}>
                        <div className='flex'>
                            <img className="flex-grow-1 market-feed-image"
                                src={props.market.marketImage == "null" ? "/images/dummy.svg" : props.market.marketImage}
                                style={{ width: '100%', height: "400px", maxHeight: '300px', objectFit: "cover", borderRadius: "6px" }} alt='' />
                        </div>
                    </Link>
                    <div className='feed-float text-light flex justify-content-between px-3'>
                        <div className='feed-float-border flex justify-content-center bg-dark'>
                            <p className='chances-text '>
                                <span style={{ color: '#069514', fontSize: 24 }}>{props.market.yesAnswerProbability}</span>
                            </p>
                            <button className='bet-btn bet-yes' onClick={handleOpenModal}>Bet YES</button>
                            <p className='chances-text '>
                                <span style={{ color: '#ff0000', fontSize: 24 }}>{props.market.noAnswerProbability}</span>
                            </p>
                            <button className='bet-btn bet-no' onClick={handleOpenModal}>Bet NO</button>
                        </div>
                    </div>
                </div>

                {/* Modal start*/}
                {/* {showModal && (
                <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content" style={{ width: 'auto' }}>
                            <div className="modal-header">
                                <h5>How to place a Bet</h5>
                                <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ padding: '20px', textAlign: 'center' }}>

                                    <p>Follow the steps in this video to place a bet easily and securely.</p>
                                </div>
                                <div style={{ display: "flex", height: "60vh" }}>
                                    <iframe
                                        src="../videos/Bet.mp4"
                                        style={{ flex: 1, width: '100%', height: '100%' }}
                                        // frameBorder="0"
                                        allowFullScreen
                                        title="How to place a Bet"
                                    />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            )} */}
                {isModalOpen && (
                    <div className="modal">
                        <div className={`${themeClass === "dark"
                            ? "text-white modal-content bg-dark"
                            : "modal-content"}`}>
                            <span className="modal-close"
                                onClick={handleCloseModal}>
                                <img src="./images/cross.png" alt="close" style={{ width: "40px" }} />
                            </span>
                            <h4 style={{ width: "90%" }}>{props.market.question}</h4>
                            <div className="d-flex justify-content-center gap-4 my-3">
                                <div className={`${modalTheme === "green-theme"
                                    ? "modal-bet-yes"
                                    : "modalBetYesToggle"
                                    }`}
                                    onClick={() => {
                                        setModalBtnColor("modalBtnGreen");
                                        setModalTheme("green-theme");
                                        setAnswer("yes");

                                    }}>Bet YES &uarr;</div>
                                <div className={`${modalTheme === "red-theme"
                                    ? "modal-bet-no"
                                    : "modalBetNoToggle"
                                    }`}
                                    onClick={() => {
                                        setModalBtnColor("modalBtnRed");
                                        setModalTheme("red-theme");
                                        setAnswer("no");
                                    }}
                                >Bet NO &darr;</div>
                            </div>
                            <div className={`${themeClass === 'dark'
                                ? `${modalTheme} bet-amount-section d-flex rounded col-md-12 text-white bg-dark`
                                : `${modalTheme} bet-amount-section d-flex rounded col-md-12`
                                }`}>

                                <div className="col-md-4">
                                    <div className="mb-2">Amount</div>
                                    <div className="d-flex flex-column">
                                        <div className="modal-input-container form-outline d-flex justify-content-start align-items-center my-3" style={{
                                            border: '1px solid #d2d2d2',
                                            borderRadius: '6px'
                                        }}>
                                            <div className='ml-2 pr-2'>$</div>
                                            <input
                                                type="text"
                                                className="bet-modal-input border-0"
                                                onChange={handleChangeModal}
                                                onKeyDown={handleKeyPress}
                                                placeholder="Enter value"
                                                value={modalPrice}
                                                readOnly="true"
                                            />
                                            <div style={{ p: 0, display: "flex", flexDirection: "column", alignItems: "center" }} >
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: '20px',
                                                        height: '20px',
                                                        fontSize: '14px',
                                                        textAlign: 'center',
                                                        lineHeight: '30px',
                                                        color: '#069514'

                                                    }}
                                                    onClick={() => handleClick('increase')}
                                                >
                                                    &#9650;
                                                </div>
                                                <div
                                                    className="increase-amount"
                                                    style={{ color: "#069514" }}
                                                    onClick={() => handleClick('increase')}
                                                >+{rangePrice}
                                                </div>
                                                <div
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: '20px',
                                                        height: '20px',
                                                        fontSize: '14px',
                                                        textAlign: 'center',
                                                        lineHeight: '9px',
                                                        color: '#069514'
                                                    }}
                                                    onClick={() => handleClick('decrease')}
                                                >
                                                    &#9660;
                                                </div>
                                            </div>
                                        </div>
                                        <div> YES</div>
                                        <div>{props.market.yesAnswerProbability}</div>
                                    </div>
                                </div>
                                <div className="col-md-8 pl-3">
                                    <div className="flex justify-content-end mb-4">
                                        <div><span style={{ color: "#828282", textAlign: "right" }}>Balance</span> ${availableBalance}</div>
                                    </div>
                                    <div className="range mb-3">
                                        <input type="range"
                                            className="form-range"
                                            min="1"
                                            max="100"
                                            value={rangePrice}
                                            readOnly
                                            onChange={(e) => {
                                                setModalPrice(Number(e.target.value))
                                                setRangePrice(Number(e.target.value))
                                            }}
                                            step="1"
                                            id="customRange3"
                                        />
                                    </div>
                                    ${rangePrice}
                                    <div>No</div>
                                    <div>{props.market.noAnswerProbability}</div>
                                </div>
                            </div>
                            <div className="flex justify-content-center">
                                <button className={`${modalBtnColor} modal-btn`} onClick={betPlaced} disabled={betLoading}>
                                    {betLoading ? (<div class="spinner-border" role="status" />) : `Bet ${modalPrice}`}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {/* Modal end*/}
            </section>
        </>
    )
}

export default DashboardHomeMarketComponent