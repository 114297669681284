import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Home";
import Signup from './Signup';
import Signin from './Signin';
import ForgotPassword from './ForgotPassword';
import Verify from './Verify';
import ResetPassword from './ResetPassword';
import PasswordResetSuccess from './PasswordResetSuccess';
import VerifyReset from './VerifyReset';
import DashboardHome from './Dashboard/DashboardHome';
import CreateMarket from './Dashboard/CreateMarket';
import EditProfile from './Dashboard/EditProfile';
import ProfilePage from './Dashboard/ProfilePage';
import ModeContext from './Context/ModeContext';
import { useState } from 'react';
import './App.css';
import { ThemeProvider } from './Context/ThemeContext';
import RightSidePanel from './Components/RightSidePanel';
import OtherProfilePage from './Dashboard/OtherProfile';
import NotFound from './Components/NotFound';
import Private from './Components/Private';
import Market from './Dashboard/Market';
import MarketGridView from './Dashboard/MarketGridView';
import MarketDetail from './Dashboard/MarketDetail';
import Notifications from './Dashboard/Notifications';
import Leagues from './Dashboard/Leagues';
import Group from './Dashboard/Group';
import Sitemap from './Dashboard/Sitemap';
import About from './Dashboard/About';
import Referrals from './Dashboard/Referrals';
import Newsletter from './Dashboard/Newsletter';
import LeaderBoard from './Dashboard/LeaderBoard';
import notificationContext from './Context/NotificationContext';
import { LoginContext } from './Context/LoginContext';
import { Helmet } from "react-helmet";
import PrivacyPolicy from './Dashboard/PrivacyPolicy';
import Faq from './Dashboard/Faq';
import Contactus from './Dashboard/Contactus';
import Metamask from './Dashboard/Metamask';
import AboutUs from './AboutUs';
import TermsConditions from './TermsConditions';
import HowToCreateWallet from './Dashboard/howToCreateWallet';
import HowToCreateMarket from './Dashboard/howToCreateMarket';
import HowToCompleteKYC from './Dashboard/howToCompleteKYC';
import HowToPlaceBet from './Dashboard/howToPlaceBet';

const AddSEO = ({ title, description, keywords, Component }) => {
  return <>
    <Helmet>
      <title>{title ? `${title} - Smarter Bet` : "Smarter Bet"}</title>
      <meta
        name="description"
        content={description || "Default Description"}
      />
      <meta
        name="keywords"
        content={keywords?.length > 0 ? keywords.join(", ") : ""}
      />

    </Helmet>
    {Component}
  </>
}

function App() {

  const theme = localStorage.getItem('theme');
  const [localTheme, setLocalTheme] = useState(theme);
  const [showNotification, setShowNotification] = useState(2);
  const [status, setStatus] = useState(false);

  return (
    <div>
      <notificationContext.Provider value={{ showNotification: showNotification }}>
        <ThemeProvider>
            {/* <ModeContext.Provider value={{ localTheme, setLocalTheme }}> */}
              {/* <LoginContext.Provider value={{ status, setStatus }} > */}
                <Routes>
                  <Route path='/' element={<Home />}
                    // <AddSEO
                    //   title={'Home'}
                    //   description={"Welcome to home page!!"}
                    //   Component={<Home />}
                    // />
                   />
                  <Route path='/signup' element={<Signup />}
                    // <AddSEO
                    //   title={'Sign Up'}
                    //   description={"Sign Up!!"}
                    //   Component={<Signup />}
                    // />

                   />
                  <Route path='/signin' element={<Signin />}
                    // <AddSEO
                    //   title={'Signin'}
                    //   description={"Sign Up!!"}
                    //   Component={<Signin />}
                    // />
                   />
                  <Route path='/forgot-password' element={<ForgotPassword />}
                    // <AddSEO
                    //   title={'Forgot Password'}
                    //   description={"Forgot Password!!"}
                    //   Component={<ForgotPassword />}
                    // />
                   />
                  <Route path='/verify-account' exact element={<Verify />}
                    // <AddSEO
                    //   title={'Verify Account'}
                    //   description={"Verify Account!!"}
                    //   Component={<Verify />}
                    // />
                   />
                  <Route path='/reset-password' exact element={<ResetPassword />}
                    // <AddSEO
                    //   title={'Reset Password'}
                    //   description={"Reset Password!!"}
                    //   Component={<ResetPassword />}
                    // />
                   />
                  <Route path='/verify-reset' element={<VerifyReset />}
                    // <AddSEO
                    //   title={'Verify Reset'}
                    //   description={"Verify Password!!"}
                    //   Component={<VerifyReset />}
                    // />
                   />
                  <Route path='/password-reset-successfully' element={<PasswordResetSuccess />}
                    // <AddSEO
                    //   title={'Password Reset Successfully'}
                    //   description={"Password Reset!!"}
                    //   Component={<PasswordResetSuccess />}
                    // />
                   />
                  <Route path='/dashboard-home' element={<DashboardHome/>}
                  // element={
                  //   <AddSEO
                  //     title={'Dashboard Home'}
                  //     description={"Dashboard Home!!"}
                  //     Component={<Private Component={DashboardHome} />}
                  //   />}
                  />
                  <Route path='/dashboard-create-market' element={<CreateMarket/>}
                    // <AddSEO
                    //   title={'Create Market'}
                    //   description={"Create Market!!"}
                    //   Component={<Private Component={CreateMarket} />}
                    // />}
                  />
                  <Route path='/dashboard-edit-profile' element={<EditProfile/>}
                    // <AddSEO
                    //   title={'Edit Profile'}
                    //   description={"Edit Profile!!"}
                    //   Component={<Private Component={EditProfile} />}
                    // />
                   />
                  <Route path='/dashboard-edit-profile/:key' element={<EditProfile/>} />
                  <Route path='/how-to-create-wallet' element={<HowToCreateWallet/>} />
                  <Route path='/how-to-create-market' element={<HowToCreateMarket/>} />
                  <Route path='/how-to-complete-kyc' element={<HowToCompleteKYC/>} />
                  <Route path='/how-to-place-bet' element={<HowToPlaceBet/>} />

                  <Route path='/dashboard-profile' element={<ProfilePage/>}
                    // <AddSEO
                    //   title={'Profile'}
                    //   description={"Profile!!"}
                    //   Component={<Private Component={ProfilePage} />}
                    // />
                   />
                  <Route path='/dashboard-other-profile/:_id' element={<OtherProfilePage/>}
                    // <AddSEO
                    //   title={'Profile'}
                    //   description={"Profile!!"}
                    //   Component={<Private Component={OtherProfilePage} />}
                    // />
                   />
                  <Route path='/dashboard-market' element={<Market/>}
                    // <AddSEO
                    //   title={'Market'}
                    //   description={"Market!!"}
                    //   Component={<Private Component={Market} />}
                    // />
                   />
                  <Route path='/dashboard-market-grid' element={<MarketGridView/>}
                    // <AddSEO
                    //   title={'Market'}
                    //   description={"Market!!"}
                    //   Component={<Private Component={MarketGridView} />}
                    // />
                   />
                  <Route path='/dashboard-market-detail/:_id' element={<MarketDetail/>}
                    // <AddSEO
                    //   title={'Market Detail'}
                    //   description={"Market Detail!!"}
                    //   Component={<Private Component={MarketDetail} />}
                    // />
                   />
                  <Route path='/dashboard-notifications' element={<Notifications/>}
                    // <AddSEO
                    //   title={'Notification'}
                    //   description={"Notification!!"}
                    //   Component={<Private Component={Notifications} />}
                    // />
                   />
                  <Route path='/dashboard-leagues' element={<Leagues/>}
                    // <AddSEO
                    //   title={'Leagues'}
                    //   description={"Leagues!!"}
                    //   Component={<Private Component={Leagues} />}
                    // />
                   />
                  <Route path='/dashboard-group' element={<Group/>}
                    // <AddSEO
                    //   title={'Group'}
                    //   description={"Group!!"}
                    //   Component={<Private Component={Group} />}
                    // />
                  />
                  <Route path='/dashboard-sitemap' element={<Sitemap/>}
                    // <AddSEO
                    //   title={'Sitemap'}
                    //   description={"Sitemap!!"}
                    //   Component={<Private Component={Sitemap} />}
                    // />
                   />
                  <Route path='/dashboard-about' element={<About/>}
                    // <AddSEO
                    //   title={'About'}
                    //   description={"About!!"}
                    //   Component={<Private Component={About} />}
                    // />
                   />
                  <Route path='/dashboard-referal' element={<Referrals/>}
                    // <AddSEO
                    //   title={'Referrals'}
                    //   description={"Referrals!!"}
                    //   Component={<Private Component={Referrals} />}
                    // />
                   />
                  <Route path='/dashboard-newletter' element={<Newsletter/>}
                    // <AddSEO
                    //   title={'Newsletter'}
                    //   description={"Newsletter!!"}
                    //   Component={<Private Component={Newsletter} />}
                    // />
                   />
                  <Route path='/dashboard-leaderboard' element={<LeaderBoard/>}
                    // <AddSEO
                    //   title={'Leader Board'}
                    //   description={"Leader Board!!"}
                    //   Component={<Private Component={LeaderBoard} />}
                    // />
                  />
                  <Route path='/privacy-policy' element={< PrivacyPolicy />}
                    // <AddSEO
                    //   title={'Privacy Policy'}
                    //   description={"Privacy Policy!!"}
                    //   Component={< PrivacyPolicy />}
                    // />
                   />
                  <Route path='/faq' element={<Faq/>}
                    // <AddSEO
                    //   title={'Frequently Asked Questions '}
                    //   description={"Frequently Asked Questions !!"}
                    //   Component={<Private Component={Faq} />}
                    // />
                   />
                  <Route path='/contact-us' element={<Contactus/>}
                    // <AddSEO
                    //   title={'Contact Us'}
                    //   description={"Contact Us!!"}
                    //   Component={<Private Component={Contactus} />}
                    // />
                   />
                  <Route path='/about-us' element={<AboutUs />}
                    // <AddSEO
                    //   title={'About Us'}
                    //   description={"About Us!!"}
                    //   Component={<AboutUs />}
                    // />}
                     />
                  <Route path='/terms-conditions' element={<TermsConditions />}
                    // <AddSEO
                    //   title={'Terms and Conditions'}
                    //   description={"Terms and Conditions!!"}
                    //   Component={<TermsConditions />}
                    // />} 
                    />
                  <Route path='/metamask' element={<Metamask />} />
                  <Route path='/version' element={"V1.0.1"} />
                  <Route path="*" element={<NotFound />}
                    // <AddSEO
                    //   title={'404 Page Not Found'}
                    //   description={"404!!"}
                    //   Component={<NotFound />}
                    // />
                   />
                </Routes>
              {/* </LoginContext.Provider> */}
            {/* </ModeContext.Provider> */}
          {/* </BrowserRouter> */}
        </ThemeProvider>
      </notificationContext.Provider>
    </div >
  );
}

export default App;
