import React from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import { useContext, useState, useEffect } from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import './styles/MarketGridView.css'
import { Link } from 'react-router-dom'
import moment from 'moment'

const MarketGridView = (props) => {

    const { themeClass, themeInput } = useContext(ThemeContext);
    const arr = [1, 2, 3, 4, 5, 6];

    const maxLength = 80; // Maximum length of the truncated string
    const { market } = props;

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.substr(0, maxLength) + '...';
        }
        return str;
    };

    return (
        <>
            <Link to={`/dashboard-market-detail/${props._id}`} >
                <div className="">
                    <div className="my-2 d-flex justify-content-start align-items-center">
                        <div className="p-2 d-flex flex-column justify-content-center"
                            style={{
                                boxShadow: "0px 0px 20px #a59c9c",
                                borderRadius: "10px",
                                minHeight: "200px",
                                width: "100%",
                                gap: "10px"
                            }}>
                            <div className='d-flex justify-content-start align-items-center gap-3 pb-2'>
                                <img className="profile-image" src={props.market.createdBy?.profile_picture || "./images/profile_image.svg"} alt="profile"></img>
                                <div>{props.market.createdBy === null ? 'User' : props.market.createdBy?.firstName + " " + props.market.createdBy?.lastName}</div>
                                <img src="./images/Vector(10).svg" alt="verified"></img>
                            </div>
                            <div style={{wordBreak:"break-word"}}>{truncateString(market.question, maxLength)}</div>
                            {/* <div className="progress mb-2 mt-1">
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "39%", paddingBlock: "4px" }}>39%</div>
                            </div> */}
                            <div className="d-flex justify-content-between align-items-center text-secondary" style={{ fontSize: "15px" }}>
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                    {/* <img src="./images/icon_fire(Hight-Stakes).svg" alt="high stakes" /> */}
                                    <div className="d-flex justify-content-center align-items-center gap-2">
                                        <img width="15px" src="/images/icon_clock.svg" alt="user" />
                                        <div>{moment(props.market.date).format('lll')}</div>
                                    </div>
                                    {/* <div>Space</div> */}
                                </div>
                                <div className="py-1 px-3" style={{ backgroundColor: "#E8E8E8", borderRadius: "100px" }}>
                                  {props.market.addToGroup}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link >
        </>
    )
}

export default MarketGridView