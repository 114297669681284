import React from "react";
import LeftSidePanel from "../Components/LeftSidePanel";
import { Link } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../Context/ThemeContext";
import "./styles/Market.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import MarketCards from "../Components/MarketCards";
import MarketGridView from "./MarketGridView";
import { axiosInstance } from "../network/config/adapter";
import { ApiEndpoints } from "../network/config/endpoints";
import Select from "react-select";
import { Height } from "@mui/icons-material";

const Market = () => {
  const { themeClass, themeInput } = useContext(ThemeContext);
  const [allMarketData, setAllMarketData] = useState([]);
  const [checkView, setCheckView] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedGrpOption, setSelectedGrpOption] = useState("");
  const [grpOption, setGrpOption] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectColor, setSelectColor] = useState("");

  useEffect(() => {
    if (themeClass === "dark") {
      setSelectColor("black");
    } else {
      setSelectColor("white");
    }
  }, [themeClass]);
  //Get Market detail API call
  useEffect(() => {
    async function getMarket(searchValue, selectedOption, selectedGrpOption) {
      try {
        const response = await axiosInstance.get(
          ApiEndpoints.market.GET_ALL_MARKET,
          {
            params: {
              search: searchValue,
              bet_status: selectedOption,
              group_name: selectedGrpOption,
            },
          }
        );

        setAllMarketData(response.data.markets);
      } catch (error) {
      }
    }
    getMarket(searchValue, selectedOption, selectedGrpOption);
    makeRequest();
  }, [searchValue, selectedOption, selectedGrpOption]);

  const [searchParams, setSearchParams] = useSearchParams();
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    setCheckView(searchParams.get("view"));
  }, [searchParams]);

  function changeView() {
    if (checkView === "grid") {
      setSearchParams({});
    } else {
      setSearchParams({ view: "grid" });
    }
  }

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "90%",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: selectColor,
      width: "150px",
      height: "40px !important",
      // Add other styles as needed
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: selectColor,
    }),
    option: (provided, state) => ({
      ...provided,

      backgroundColor: themeClass === "dark" ? "black" : "white",
      color: themeClass === "dark" ? "white" : "black",
      // backgroundColor: state.isSelected ? '#069514' : state.isFocused ? 'lightblue' : 'white',
      // color: state.isSelected ? 'white' : state.isFocused ? 'black' : 'black',
      // Add other styles as needed
    }),
    singleValue: (provided) => ({
      ...provided,
      color: themeClass === "dark" ? "white" : "black", // Change the color of the selected item here
      // Add other styles as needed
    }),
  };

  async function makeRequest() {
    try {
      const response = await axiosInstance.get(ApiEndpoints.group.GROUP_DETAIL);
      // Assuming the API response contains an array of objects with a 'group_name' property
      const groupNames = response.data.map((item) => ({
        value: item.group_name,
        label: item.group_name,
      }));

      setGrpOption(groupNames);
    } catch (error) {
      return error;
    }
  }
  return (
    <>
      <div className="container">
        <div className="row d-flex justify-content-center align-items-start">
          {/* ---------------------Column 1--------------------- */}

          <LeftSidePanel />

          {/* ---------------------Column 2--------------------- */}
          <div className="col-md-9">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="mt-5">
                    <h3>Markets</h3>
                    <div
                      className="container mb-4 col-md-12 d-md-flex d-none justify-content-center align-items-center gap-2"
                      style={{ padding: "0px", overflow: "unset" }}
                    >
                      
                      <div
                        className="col-md-8"
                        style={{ padding: "0px", flex: '53%' }}
                      >
                        <div
                          className="search-bar form-outline"
                          style={{
                            border: "1px solid #d2d2d2",
                            borderRadius: "6px",
                          }}
                        >
                          <input
                            type="search"
                            id="form1"
                            className={`${themeInput} search-bar form-control shadow-none`}
                            placeholder="Search"
                            aria-label="Search"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className=" ">
                        <select
                          className={`${
                            themeClass === "dark"
                              ? "text-white bg-dark form-select"
                              : "form-select"
                          }`}
                          aria-label="Default select example"
                          onChange={handleSelectChange}
                        >
                          <option value="open">Open</option>
                          <option value="closed">Closed</option>
                          <option value="resolved">Resolved</option>
                          <option value="" selected>
                            All
                          </option>
                        </select>
                      </div>
                      <div style={{ width: "110px" }}>
                        <select
                          className={`${
                            themeClass === "dark"
                              ? "text-white bg-dark form-select"
                              : "form-select"
                          }`}
                          //   flex={2}
                          //   defaultValue={selectedGrpOption}
                          //   menuIsOpen={isMenuOpen}
                          //   onMenuOpen={handleMenuOpen}
                          //   onMenuClose={handleMenuClose}
                          onChange={(e) =>
                            setSelectedGrpOption(e.target.value)
                          }
                          //   options={grpOption}
                            placeholder="eg. Science"
                          //   autoHeight={false}
                          //   styles={customStyles}
                        >
                        {grpOption.map((item,index)=>(
                           <option key={index} value={item.value}>{item.label}</option>
                        ))}
                        </select>
                      </div>
                      <img
                        className="change-view-img"
                        src="./images/icon_grid_view.svg"
                        alt="grid"
                        onClick={changeView}
                      />
                    </div>

                    {/* responsive */}
                    <div class="container mb-4 d-block d-md-none">
                      <div class="row justify-content-center align-items-center gap-2">
                        <div class="col-md-12 mt-2 mt-md-2">
                          <div class="row">
                            <div class="col-10 col-sm-10 col-md-10">
                              <div className="search-bar form-outline"
                                style={{
                                  border: "1px solid #d2d2d2",
                                  borderRadius: "6px",
                                }}>
                                <input type="search" id="form1" class="search-bar form-control shadow-none" placeholder="Search" aria-label="Search" onChange={handleInputChange} />
                              </div>
                            </div>
                            <div class="col-2 col-sm-2 mt-2">
                              <img class="change-view-img" src="./images/icon_grid_view.svg" alt="grid" onClick={changeView} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-center align-items-center gap-2">
                        <div class="col-md-12 mt-2 mt-md-2">
                          <div class="row">
                            <div class="col col-sm-6 col-md-6">
                              <select
                                className={`${themeClass === "dark"
                                  ? "text-white bg-dark form-select"
                                  : "form-select"
                                  }`}
                                aria-label="Default select example"
                                onChange={handleSelectChange}
                              >
                                <option value="open">Open</option>
                                <option value="closed">Closed</option>
                                <option value="resolved">Resolved</option>
                                <option value="" selected>
                                  All
                                </option>
                              </select>
                            </div>
                            <div class="col col-sm-6 col-md-6">
                              <select
                                className={`${themeClass === "dark"
                                    ? "text-white bg-dark form-select"
                                    : "form-select"
                                  }`}
                                //   flex={2}
                                  defaultValue={selectedGrpOption}
                                //   menuIsOpen={isMenuOpen}
                                //   onMenuOpen={handleMenuOpen}
                                //   onMenuClose={handleMenuClose}
                                onChange={(e) =>
                                  setSelectedGrpOption(e.target.value)
                                }
                                
                                //   options={grpOption}
                                placeholder="eg. Science"
                              //   autoHeight={false}
                              //   styles={customStyles}
                              >
                                {grpOption.map((item, index) => (
                                  <option key={index} value={item.value}>{item.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    {/* <div className="container mb-4 col-md-12 d-flex justify-content-between align-items-center gap-2" style={{ padding: "0px", overflow: "unset" }}>
                      <div className="col-md-9 col-sm-9 d-flex" style={{ padding: "0px" }}>
                        <div className="search-bar form-outline" style={{ border: "1px solid #d2d2d2", borderRadius: "6px" }}>
                          <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search" aria-label="Search" onChange={handleInputChange} />
                        </div>
                      </div>
                        <img className="change-view-img" src="./images/icon_grid_view.svg" alt="grid" onClick={changeView} />
                    </div>

                    <div className="container col-md-12 d-flex align-items-start align-items-md-center">
                      <div className="col-sm-6  d-flex">
                        <select className={`${themeClass === "dark" ? "text-white bg-dark form-select" : "form-select"}`} aria-label="Default select example" onChange={handleSelectChange}>
                          <option value="open">Open</option>
                          <option value="closed">Closed</option>
                          <option value="resolved">Resolved</option>
                          <option value="" selected>All</option>
                        </select>
                      </div>
                      <div className="col-sm-6  d-flex">
                        <select className={`${themeClass === "dark" ? "text-white bg-dark form-select" : "form-select"}`} onChange={(selectedOption) => setSelectedGrpOption(selectedOption.value)} placeholder="eg. Science">
                          {grpOption.map((item, index) => (
                            <option key={index} value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                    </div> */}
                  </div>
                  <div className="pb-5">
                    {/* <div className='d-flex justify-content-between align-items-center mb-2'> */}
                    {/* <div className=''>
                                                <div>Question</div>
                                            </div> */}
                    {/* <div className='col-sm-2'>
                                            </div> */}
                    {/* <div className='flex gap-3'>
                                                <div>Prob</div>
                                                <div>Traders</div>
                                            </div> */}
                    {/* </div> */}
                    {checkView === "grid" ? (
                      <>
                        <div className="row">
                          {[...allMarketData].reverse().map((market) => (
                            <div key={market._id} className="col-md-6 col-lg-6">
                              <MarketGridView
                                market={market}
                                _id={market._id}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="">
                            <div>Question</div>
                          </div>
                          <div className="flex gap-3">
                            {/* <div>Prob</div> */}
                            <div>Traders</div>
                          </div>
                        </div>
                        {[...allMarketData].reverse().map((market) => (
                          <MarketCards
                            key={market._id}
                            market={market}
                            _id={market._id}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Market;
