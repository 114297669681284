import React from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import './styles/Sitemap.css'
import { XIcon } from '@mui/icons-material/X';
import { CiWallet } from "react-icons/ci";

const Sitemap = () => {

    const { themeClass, themeInput } = useContext(ThemeContext);

    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-start">
                {/* ---------------------Column 1--------------------- */}

                <LeftSidePanel />

                {/* ---------------------Column 2--------------------- */}
                <div className="col-md-9">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mt-5 mb-4">
                                    <h3>Sitemap</h3>
                                    <div className="flex flex-column pb-5">
                                        <div className="sitemap-wrapper col-md-12 flex mt-5 p-0">
                                            <div className="col-md-6">
                                                <Link to="/dashboard-about">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/icon_help.svg" alt="help" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`} >About & Help</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="sitemap-card flex justify-content-start gap-3">
                                                    <img className="sitemap-card-img" src="./images/icon_discord(1).svg" alt="discord" />
                                                    <div className={`${themeClass === "dark"
                                                        ? "text-light"
                                                        : "text-dark"
                                                        }`}>Discord</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="sitemap-wrapper col-md-12 flex mt-4 p-0">
                                            <div className="col-md-6">
                                                <Link to="/dashboard-newletter">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/icon_newsletter.svg" alt="newsletter" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Newsletter</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="javascript:void(0)"
                                                    onClick={() => window.location = 'mailto:support@smarterbet.com'}>
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/icon_email(1).svg" alt="email" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Email</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="sitemap-wrapper col-md-12 flex mt-4 p-0">
                                            <div className="col-md-6">
                                                <Link to="https://twitter.com/smarter__bet">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/twitter.svg" alt="twitter" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Twitter</div>
                                                    </div>
                                                </Link>

                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/dashboard-referal">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/icon_Referrals.svg" alt="Referrals" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Referrals</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="sitemap-wrapper col-md-12 flex mt-4 p-0">
                                            {/* <div className="col-md-6">
                                                <Link to="/dashboard-leaderboard">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/Group(10).svg" alt="leaderBoards" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Leader Boards</div>
                                                    </div>
                                                </Link>
                                            </div> */}
                                            <div className="col-md-6">
                                                <Link to="/faq">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/faq.png" alt="faq" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>FAQ
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/privacy-policy">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/privacy-policy.png" alt="leaderBoards" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Privacy Policy</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="sitemap-wrapper col-md-12 flex mt-4 p-0">
                                            <div className="col-md-6">
                                                <Link to="/contact-us">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/contact-us.png" alt="faq" />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>Contact Us
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/how-to-complete-kyc">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/kyc1.png" alt="faq" />
                                                        {/* <CiWallet size={"48px"} /> */}

                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>How to complete KYC?
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="sitemap-wrapper col-md-12 flex mt-4 p-0">
                                            <div className="col-md-6">
                                                <Link to="/how-to-create-wallet">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/wallet1.png" alt="faq" />
                                                        {/* <CiWallet size={"48px"} /> */}

                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>How to Connect Wallet?
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="/how-to-create-market">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/market_icon.svg" alt="Create Market?" style={{ objectFit: "none" }} />
                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>How to Create Market?
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="sitemap-wrapper col-md-12 flex mt-4 p-0">
                                            <div className="col-md-6">
                                                <Link to="/how-to-place-bet">
                                                    <div className="sitemap-card flex justify-content-start gap-3">
                                                        <img className="sitemap-card-img" src="./images/bet.png" alt="faq" />
                                                        {/* <CiWallet size={"48px"} /> */}

                                                        <div className={`${themeClass === "dark"
                                                            ? "text-light"
                                                            : "text-dark"
                                                            }`}>How to Place a Bet?
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sitemap