import { useEffect } from "react";
import { Link } from "react-router-dom"

function HowToPlaceBet() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container" style={{ paddingBlock: "20px", width: "100%", display: 'flex', flexDirection: 'column' }}>
            <Link to={-1}>
                <button className="mr-4" style={{ border: "none", width: "35px", height: "35px", borderRadius: "30px" }}>
                    <img src="./images/icon_back.svg" alt="back" />
                </button>
            </Link>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <h3>How to place a Bet</h3>
                <p>Follow the steps in this video to place a bet easily and securely.</p>
            </div>
            <div style={{ display: "flex", height: "80vh" }}>
                <iframe
                    src="../videos/Bet.mp4"
                    style={{ flex: 1, width: '100%', height: '100%' }}
                    // frameBorder="0"
                    allowFullScreen
                    title="How to Connect a Wallet"
                />
            </div>
            <div style={{ padding: '20px', display: "flex", flexDirection: "column", gap: 20 }}>
                <p>0:00: In this video, I'm going to show you how to place a bet step by step to ensure you are compliant to trade on the platform.</p>
                <p>0:07: Hi, I'm Nick from Smarter Bet.</p>
                <p>0:09: This video will guide you through the process of placing a bet in a market so that you can start trading on the outcome of events.</p>
                <p>0:15: Let's get started.</p>
                <p>0:17: The first step to placing a bet is to identify a market that you are interested in betting on from the home page or Markets page.</p>
                <p>0:24: Once you have identified a market, there are two ways to place a bet.</p>
                <p>0:28: The first and quickest way is to click bet, yes or bet no from the market's landing page.</p>
                <p>0:33: This will bring up a pop up as shown where you can see the current probabilities.</p>
                <p>0:38: You can choose to bet, yes or bet no, as well as the amount you want to bet.</p>
                <p>0:43: The other way is to click the market's title on the market's landing page, which will take you to that specific market page there.</p>
                <p>0:49: You can see the graph, the comments, the trades as well as your existing trades in this market.</p>
                <p>0:58: You will also be prompted with bet.</p>
                <p>1:00: Yes and bet, no buttons, whichever one you select will open the same pop up as shown under the first option where you can see the current probabilities.</p>
                <p>1:09: You can choose to bet.</p>
                <p>1:09: Yes or bet no, as well as the amount you want to bet.</p>
                <p>1:13: I hope this has helped you place your bets and I wish you the best of luck with your upcoming bets.</p>
                <p>1:18: If you have any concerns or questions, feel free to reach out to us on the Contact Us page and I would be happy to address them.</p>
                <p>1:25: Thanks for watching.</p>
            </div>

        </div>

    )
}
export default HowToPlaceBet