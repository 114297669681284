import React from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import './styles/Leagues.css'

const Leagues = () => {

    const { themeClass, themeInput } = useContext(ThemeContext);
    const [active, setActive] = useState("");


    const handleSetActive = (e, newValue) => {
        e?.preventDefault();
        setActive(newValue);
    }

    let tempArr = [1, 2, 3, 4, 5];

    // Open first tab
    useEffect(() => {
        // Function to simulate a click event on the "Portfolio" tab link
        const openNotificationsTab = () => {
            const defaultOpenLink = document.getElementById("default-open");
            if (defaultOpenLink) {
                defaultOpenLink.click();
            }
        };

        // Call the function to open the "Portfolio" tab when the component mounts
        openNotificationsTab();
    }, []);
    
    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-start">
                {/* ---------------------Column 1--------------------- */}

                <LeftSidePanel />

                {/* ---------------------Column 2--------------------- */}
                <div className="col-md-9">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mt-5 mb-4">
                                    <h3>Leagues</h3>
                                    <div className='leagueSubHeader flex justify-content-start mt-4 mb-3' style={{ padding: '0px' }}>
                                        <h5>Compete against similarly skilled users for <span className="prizesText">prizes</span> and promotion by earning the most mana this month!</h5>
                                    </div>
                                    <div className="schedule-container flex flex-column gap-4">
                                        <div className="schedule-wrapper flex col-md-12 ps-0">
                                            <div className="col-md-3 ps-0">
                                                <select className={`${themeClass === 'dark' ? "text-white bg-dark form-select" : "form-select"}`} aria-label="Default select example">
                                                    <option value="season1">Season 1: May</option>
                                                    <option value="season2">Season 2: June</option>
                                                    <option value="season3">Season 3: July</option>
                                                </select>
                                            </div>
                                            <div className={`${themeClass === "dark"
                                                ? "bg-dark schedule py-2 px-5 rounded"
                                                : "schedule py-2 px-5 rounded"}`}
                                            >
                                                <div className="flex gap-2">
                                                    <img src="./images/icon_clock.svg" alt="countdown" />
                                                    <p>Ends in 9d 1h 27m</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex col-md-12 ps-0">
                                            <div className="col-md-3 ps-0">
                                                <select className={`${themeClass === 'dark' ? "text-white bg-dark form-select" : "form-select"}`} aria-label="Default select example">
                                                    <option value="master">Master</option>
                                                    <option value="diamond">Diamond</option>
                                                    <option value="platinum">Platinum</option>
                                                    <option value="gold">Gold</option>
                                                    <option value="silver">Silver</option>
                                                    <option value="bronze">Bronze</option>
                                                    <option value="silicon">Silicon</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 ps-0">
                                                <select className={`${themeClass === 'dark' ? "text-white bg-dark form-select" : "form-select"}`} aria-label="Default select example">
                                                    <option value="master">option 1</option>
                                                    <option value="diamond">option 2</option>
                                                    <option value="platinum">option 3</option>
                                                    <option value="gold">option 4</option>
                                                    <option value="silver">option 5</option>
                                                    <option value="bronze">option 6</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-5'>
                                        <div className="container">
                                            <ul className="nav nav-pills d-flex gap-3">
                                                <li onClick={(e) => handleSetActive(e, "rankings")}
                                                    className={active === "rankings" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#rankings" style={{
                                                        color: themeClass === 'dark' ? 'white' : 'black'
                                                    }}
                                                        id="default-open"
                                                        aria-expanded="true">Rankings</a>
                                                </li>
                                                <li onClick={(e) => handleSetActive(e, "activity")}
                                                    className={active === "activity" ? 'custom-tab' : 'InActive'}
                                                >
                                                    <a className='m-2' data-toggle="pill" href="#activity" style={{ color: themeClass === 'dark' ? 'white' : 'black' }}>Activity</a>
                                                </li>
                                            </ul>
                                            <hr />

                                            {/* Ranking section  */}
                                            <div className="tab-content mb-5">
                                                <div id="rankings" className="tab-pane fade">
                                                    <div className="flex justify-content-between py-2" style={{ fontWeight: "600" }}>
                                                        <div>User</div>
                                                        <div>Tether Total</div>
                                                    </div>
                                                    <div className="px-3">
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">1</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div>Kathryn Murphy</div>
                                                            </div>
                                                            <div>
                                                                ₮ 5,344
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">2</div>
                                                                <img className="league-ranking-profile" src="./images/profile_image.svg" alt="profile" />
                                                                <div>Jane Cooper</div>
                                                            </div>
                                                            <div>
                                                                ₮ 2,356
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">3</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div>Jacob Jones</div>
                                                            </div>
                                                            <div>
                                                                ₮ 7,952
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex rounded justify-content-center gap-2 my-2"
                                                        style={{ backgroundColor: "#7d58c22b", padding: "15px" }}>
                                                        <img src="./images/Polygon1.svg" alt="platinum" />
                                                        <div>Promotes to Platinum</div>
                                                    </div>

                                                    <div className="px-3">
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">4</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div>Kathryn Murphy</div>
                                                            </div>
                                                            <div>
                                                                ₮ 5,344
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">5</div>
                                                                <img className="league-ranking-profile" src="./images/profile_image.svg" alt="profile" />
                                                                <div>Jane Cooper</div>
                                                            </div>
                                                            <div>
                                                                ₮ 2,356
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">6</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div>Jacob Jones</div>
                                                            </div>
                                                            <div>
                                                                ₮ 7,952
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">7</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div style={{ width: "130px" }}>Kathryn Murphy</div>
                                                            </div>
                                                            <div>
                                                                ₮ 5,344
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">8</div>
                                                                <img className="league-ranking-profile" src="./images/profile_image.svg" alt="profile" />
                                                                <div>Jane Cooper</div>
                                                            </div>
                                                            <div>
                                                                ₮ 2,356
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`${themeClass === "dark"
                                                        ? "bg-dark rounded flex justify-content-center gap-2 my-2"
                                                        : "flex rounded justify-content-center gap-2 my-2"}`}
                                                        style={{ backgroundColor: "#FFF0ED", padding: "15px" }}>
                                                        <img src="./images/Polygon1.svg" alt="platinum" style={{ transform: "rotate(180deg)" }} />
                                                        <div>Demotes to Silver</div>
                                                    </div>

                                                    <div className="px-3">
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">9</div>
                                                                <img className="league-ranking-profile" src="./images/profile_image.svg" alt="profile" />
                                                                <div>Jane Cooper</div>
                                                            </div>
                                                            <div>
                                                                ₮ 2,356
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">10</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div>Jacob Jones</div>
                                                            </div>
                                                            <div>
                                                                ₮ 7,952
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">11</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div style={{ width: "130px" }}>Kathryn Murphy</div>
                                                            </div>
                                                            <div>
                                                                ₮ 5,344
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">12</div>
                                                                <img className="league-ranking-profile" src="./images/profile_image.svg" alt="profile" />
                                                                <div>Jane Cooper</div>
                                                            </div>
                                                            <div>
                                                                ₮ 2,356
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">13</div>
                                                                <img className="league-ranking-profile" src="./images/Ellipse5(2).svg" alt="profile" />
                                                                <div>Kathryn Murphy</div>
                                                            </div>
                                                            <div>
                                                                ₮ 5,344
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-content-between py-2">
                                                            <div className="flex justify-content-center gap-3">
                                                                <div className="league-ranking-profile-index">15</div>
                                                                <img className="league-ranking-profile" src="./images/profile_image.svg" alt="profile" />
                                                                <div>Jane Cooper</div>
                                                            </div>
                                                            <div>
                                                                ₮ 2,356
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div id="activity" className="tab-pane fade">
                                                    {tempArr.map((i) => (
                                                        <div
                                                            className={`${themeClass === "dark"
                                                                ? "bg-dark text-white notification-card d-flex align-items-center justify-content-between py-3 px-3 mt-2 gap-4"
                                                                : "notification-card d-flex align-items-center justify-content-between py-3 px-3 mt-2 gap-3"
                                                                }`}
                                                            key={i} // Key
                                                        >
                                                            <div className="d-flex align-items-center justify-content-center gap-3" style={{ color: "#828282" }}>
                                                                <img src="./images/Group(8).svg" alt="" />
                                                                <h5 style={{ color: "#069514" }} className="m-0">₮{i + 10}</h5>
                                                                <div>Bonus for your {i} day <span className={`${themeClass === "dark"
                                                                    ? "text-white"
                                                                    : "text-dark"}`}>
                                                                    Prediction Streak
                                                                </span>
                                                                </div>
                                                            </div>
                                                            <div style={{ color: "#828282" }}>
                                                                {i + 1}h ago
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leagues