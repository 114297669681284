import React, { useEffect } from 'react'
import './styles/DashboardHome.css'
import Select from 'react-select'
import { useState } from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { Link } from 'react-router-dom'
import RightSidePanel from '../Components/RightSidePanel'
import 'react-tooltip/dist/react-tooltip.css'
import ModeContext from '../Context/ModeContext'
import { useContext } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import notificationContext from '../Context/NotificationContext'
import DashboardHomeMarketComponent from '../Components/DashboardHomeMarketComponent'
import { axiosInstance } from '../network/config/adapter'
import { ApiEndpoints } from '../network/config/endpoints'
import { useAuth } from '../hooks/useAuth'

const options = [
    {
        value: 1,
        text: 'Item 1',
        icon: <img className='list-image' src='./images/bird.svg' alt="" />
    },
    {
        value: 2,
        text: 'Item 2',
        icon: <img className='list-image' src='./images/bird.svg' alt='' />
    },
    {
        value: 3,
        text: 'Item 3',
        icon: <img className='list-image' src='./images/bird.svg' alt='' />
    },
    {
        value: 4,
        text: 'Item 4',
        icon: <img className='list-image' src='./images/bird.svg' alt='' />
    }
];


const DashboardHome = () => {

    const [selectedOption, setSelectedOption] = useState('');
    const { themeClass, themeInput } = useContext(ThemeContext);
    const [fullName, setFullName] = useState('');
    const [profilePicture, setProfilePicture] = useState('/public/images/dummy-avatar-png.png');
    const [modalPrice, setModalPrice] = useState(20);
    const [rangePrice, setRangePrice] = useState();
    const [modalTheme, setModalTheme] = useState("green-theme");
    const [modalBtnColor, setModalBtnColor] = useState("modalBtnGreen");
    const [allMarketData, setAllMarketData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [grpOption, setGrpOption] = useState([]);
    const { fetchUserdata} = useAuth()
    const { user } = useAuth()
    const [showModal, setShowModal] = useState("");

    // Notification Context
    const { showNotification, updatedNotification } = useContext(notificationContext);

    const localToken = localStorage.getItem('Oddplus_Token');

    // const { localTheme, setLocalTheme } = useContext(ModeContext);
    // const [themeClass, setThemeClass] = useState('');
    // const [themeInput, setThemeInput] = useState('');


    // API call for get profile information^^^^^^ API Call ^^^^^^^
    useEffect(() => {
        async function makeRequest() {
            try {
                const response = await axiosInstance.get(ApiEndpoints.auth.PROFILE_DATA);
                console.log("ODD PLUS USR NAME",response.data.data);
                setFullName(response.data.data.full_name);
                setProfilePicture(response.data.data.profile_picture);
                fetchUserdata();
            } catch (error) {
            }
        }
        makeRequest();
    }, []);

    //Get Market detail API call^^^^^^ API Call ^^^^^^^
    useEffect(() => {
        getMarket(searchValue, selectedOption);
        makeRequest();
        // fetchUserdata();
    }, [searchValue, selectedOption,user]);

    async function getMarket(searchValue, selectedOption) {
        try {
            const response = await axiosInstance.get(ApiEndpoints.market.GET_ALL_MARKET, { params: { search: searchValue, group_name: selectedOption } });
            setAllMarketData(response.data.markets.filter(market => market.bet_status !== "close" && market.bet_status !== "distribution started" && market.bet_status !== "distributed" && market.bet_status !== "resolved"));
        } catch (error) {
        }
    }



    const handleChange = e => {
        setSelectedOption(e.target.value);
    }

    // -------------------Custom style for Select-------------------------
    const [selectColor, setSelectColor] = useState('');

    useEffect(() => {
        if (themeClass === 'dark') {
            setSelectColor('black !important');
        } else {
            setSelectColor('white');
        }
    }, [themeInput]);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            backgroundColor: selectColor,
            minWidth: '200px'
            // Add other styles as needed
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: selectColor,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: themeClass === 'dark' ? 'black' : 'white',
            color: themeClass === 'dark' ? 'white' : 'black',
            // backgroundColor: state.isSelected ? '#069514' : state.isFocused ? 'lightblue' : 'white',
            // color: state.isSelected ? 'white' : state.isFocused ? 'black' : 'black',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: themeClass === 'dark' ? 'white' : 'black', // Change the color of the selected item here
            // Add other styles as needed
        }),
    };

    // Show truncated string
    const maxLength = 10; // Maximum length of the truncated string

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.substr(0, maxLength) + '...';
        }
        return str;
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    async function makeRequest() {
        try {

            const response = await axiosInstance.get(ApiEndpoints.group.GROUP_DETAIL);
            // Assuming the API response contains an array of objects with a 'group_name' property
            const groupNames = response.data.map(item => ({
                value: item.group_name,
                label: item.group_name
            }));

            setGrpOption(groupNames);
        } catch (error) {
            return error;
        }
    }

    // // Modal box
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const handleOpenModal = () => {
    //     setIsModalOpen(true);
    // };
    // const handleCloseModal = () => {
    //     setIsModalOpen(false);
    // };

    // --------------------Only Numbers in modalbox----------------------
    // const handleKeyPress = (event) => {
    //     const isValidInput = /^[0-9]+$/.test(event.key);

    //     if (!isValidInput) {
    //         event.preventDefault();
    //     }
    // };
    // const handleChangeModal = (event) => {
    //     setModalPrice(Number(event.target.value));
    // };

    // // -----------------Set modal button color----------------------
    // useEffect(() => {
    //     if (modalTheme === "red-theme")
    //         setModalBtnColor("modalBtnRed");
    //     else
    //         setModalBtnColor("modalBtnGreen");
    // }, [modalTheme])


    // Show notification
    useEffect(() => {
        if (user?.firstLogin === true) {
          setShowModal("welcome-screen");
        }
      }, [user]);

      const kycScreen = () => {
        setShowModal("kyc-screen");
      };
      const conntectWalletScreen = () => {
        setShowModal("connect-wallet-screen");
      };
      const handleClose = () => {
        setShowModal("");
        fetchUserdata();
      };
    return (
        <>
            <div className="container">
                <div className="row d-flex justify-content-center align-items-start">
                    {/* ---------------------Column 1--------------------- */}
                    <LeftSidePanel />

                    {/* ---------------------Column 2--------------------- */}
                    <div className="col-md-6">
                        <nav className="navbar navbar-expand-sm ">
                            <div className={`${themeClass} home-text-logo navbar-brand d-flex align-items-center justify-content-start`} >Home</div>
                        </nav>

                        {/* Its show when page is open in mobile view */}
                        <div className="profile-section-upper">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                <RightSidePanel />
                            </div>
                        </div>
                        {/* -------------------------------------------------- */}

                        {/* Its show when page is open in tablet view */}
                        {/* <div className="profile-section-upper-tablet">
                            <div className="flex justify-content-center">
                                <div className="profile-section">
                                    <div className='profile-wrapper menu d-flex align-items-center justify-content-center flex-column' style={{ position: 'relative' }}>
                                        <Link to='/dashboard-profile'>
                                            <div className='d-flex align-items-center justify-content-center flex-column'>
                                                <img className='profile-pic mt-3 mb-2' src={profilePicture || "./images/dummy-avatar-png.png"} alt='' />
                                                <h6 className='profile-name'>{truncateString(fullName, maxLength)}</h6>
                                            </div>
                                        </Link>
                                        <div className=' '>4
                                            <h6 style={{ display: 'inline-block', marginRight: '8px', color: '#828282' }}>₮500</h6>
                                            <img style={{ fontSize: '16px' }} src='./images/icon_plus.svg' alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                    <RightSidePanel />
                                </div>
                            </div>
                        </div> */}
                        {/* -------------------------------------------------- */}

                        <div className="search-bar form-outline d-flex justify-content-between align-items-center" style={{ border: '1px solid #d2d2d2', borderRadius: '6px' }} >
                            <div className='ml-2'>
                                <img className='search-icon' src='./images/icon_search.svg' alt='' />
                            </div>
                            <input type="search" id="form1" className={`${themeInput} search-bar form-control shadow-none`} placeholder="Search" aria-label="Search" onChange={handleInputChange} />
                        </div>

                        <div className='d-flex justify-content-between align-items-center my-3 mb-4'>
                            <div className='d-flex justify-content-between align-items-center gap-1'>
                                {/* <Select
                                defaultValue={selectedOption}
                                onChange={setSelectedOption}
                                options={options}
                                placeholder='Just Your Topics'
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    // border: 'none',
                                    // outline: 'none',
                                    minWidth: '200px'
                                }),
                            }}
                            /> */}
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={(selectedOption) => setSelectedOption(selectedOption.value)}
                                    options={grpOption}
                                    placeholder='eg. Science'
                                    styles={customStyles}
                                />
                                {/* <img id='' src='./images/Vector(7).svg' alt='' style={{ width: '15px', paddingBottom: '22px' }} /> */}
                            </div>
                            {/* <div className='d-flex justify-content-center align-items-center gap-2'> */}
                            {/* <img src='./images/Vector(7).svg' alt='' style={{ width: '15px' }} /> */}
                            {/* <p>Live</p> */}
                            {/* <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1"/>
                                    <label class="custom-control-label" for="customSwitch1"/>
                                </div> */}
                            {/* <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="darkSwitch" />
                                    <label className="custom-control-label" htmlFor="darkSwitch" />
                                </div> */}
                            {/* </div> */}
                        </div>

                        {/* Market sections */}
                        {
                            [...allMarketData].reverse().map((market) => (
                                <DashboardHomeMarketComponent key={market._id} market={market} _id={market._id} getMarket={getMarket} />
                            ))
                        }
                    </div>

                    {/* ---------------------Column 3--------------------- */}
                    <div className="col-md-3 d-flex align-items-center justify-content-center flex-column" style={{ height: '100%' }}>
                        <div className="profile-section">
                            <div className='profile-wrapper menu d-flex align-items-center justify-content-center flex-column' style={{ position: 'relative' }}>
                                <Link to='/dashboard-profile'>
                                    <div className='d-flex align-items-center justify-content-center flex-column'>
                                        <img className='profile-pic mt-3 mb-2' src={profilePicture || "./images/dummy-avatar-png.png"} alt='' />
                                        {/* <h6 className='profile-name text-capitalize'>{truncateString(fullName, maxLength)}</h6> */}
                                        <h6 className='profile-name text-capitalize'>{fullName.split(' ')[0]}</h6>
                                    </div>
                                </Link>
                                {/* <div className=' '>
                                    <h6 style={{ display: 'inline-block', marginRight: '8px', color: '#828282' }}>₮500</h6>
                                    <img style={{ fontSize: '16px' }} src='./images/icon_plus.svg' alt='' />
                                </div> */}
                            </div>
                        </div>
                        <div className="rightside-section">
                            <RightSidePanel />
                        </div>
                    </div>


                    {/* spacer */}
                    {/* ------------------------------------------- */}
                    <section style={{ paddingBlock: '15px' }}></section>
                    {/* ------------------------------------------- */}
                </div >
                <ToastContainer />
                {showModal === "welcome-screen" && (
                    <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content" style={{ width: 'auto' }}>
                                {/* <div className="modal-header">
                                    <h5>How to Connect a Wallet</h5>
                                    <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div> */}
                                <div className="modal-body">
                                    <div style={{ padding: '20px', textAlign: 'center' }}>
                                        <img src="/images/logo.svg" alt="Company Logo" style={{ maxWidth: '250px', marginBottom: '10px' }} />
                                        <h2>Welcome to smarter-bet</h2>
                                    </div>
                                    <div style={{ padding: '20px', textAlign: 'left' }}>
                                        <h6 style={{color:"#000"}}>Welcome, <span style={{fontWeight:700}}>{user?.full_name}</span></h6>
                                        <h6 style={{color:"#000"}}>Bet with play money on politics, tech, sports, and more. Your bets
                                            contribute to the wisdom of the crowd.
                                        </h6>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    {/* <button type="button" class="btn btn-primary" onClick={conntectWalletScreen}>Next</button> */}
                                    <button type="button" class="btn btn-primary" onClick={kycScreen}>Next</button>
                                    {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showModal === "kyc-screen" && (
                    <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content" style={{ width: 'auto' }}>
                                <div className="modal-header">
                                    <h5>How to complete KYC</h5>
                                    {/* <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                                </div>
                                <div className="modal-body">
                                        <div style={{ padding: '20px', textAlign: 'center' }}>
                                          
                                            <p>Follow the steps in this video to complete your KYC easily and securely.</p>
                                        </div>
                                        <div style={{ display: "flex", height: "60vh" }}>
                                            <iframe
                                                src="../videos/KYC.mp4"
                                                style={{ flex: 1, width: '100%', height: '100%' }}
                                                // frameBorder="0"
                                                allowFullScreen
                                                title="How to Connect a Wallet"
                                            />
                                        </div>

                                    
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" onClick={conntectWalletScreen}>Next</button>
                                    {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showModal === "connect-wallet-screen" && (
                    <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content" style={{ width: 'auto' }}>
                                <div className="modal-header">
                                    <h5>How to Connect a Wallet</h5>
                                    {/* <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                                </div>
                                <div className="modal-body">
                                        <div style={{ padding: '20px', textAlign: 'center' }}>
                                          
                                            <p>Follow the steps in this video to Connect your wallet easily and securely.</p>
                                        </div>
                                        <div style={{ display: "flex", height: "60vh" }}>
                                            <iframe
                                                src="../videos/How_to_create_wallet.mp4"
                                                style={{ flex: 1, width: '100%', height: '100%' }}
                                                // frameBorder="0"
                                                allowFullScreen
                                                title="How to Connect a Wallet"
                                            />
                                        </div>

                                    
                                </div>
                                <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={handleClose}>Close</button>

                                    {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
                
            </div >
        </>
    )
}

export default DashboardHome