import React, { useCallback, useEffect, useState } from 'react'
import LeftSidePanel from '../Components/LeftSidePanel'
import { useContext } from 'react'
import { ThemeContext } from '../Context/ThemeContext'
import { Link } from 'react-router-dom'
import './styles/MarketDetail.css'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import RelatedMarketCard from '../Components/RelatedMarketCard'
import { useAuth } from '../hooks/useAuth.js'
import { axiosInstance } from '../network/config/adapter.js'
import { ApiEndpoints } from '../network/config/endpoints.js'
// import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment'
import { Avatar, Chip } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'

const MarketDetail = (props) => {
    const { _id } = useParams();
    const { themeClass, themeInput } = useContext(ThemeContext);
    const [active, setActive] = useState("");
    const [value, setValue] = useState('');
    const [tableTheme, setTableTheme] = useState('table-light');
    const [question, setQuestion] = useState("");
    const [marketImage, setMarketImage] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [description, setDescription] = useState("");
    const [answerType, setAnswerType] = useState("");
    const [choices, setChoices] = useState([]);
    const [allQuestion, setAllQuestion] = useState([]);
    const parse = require('html-react-parser');
    const [creatorName, setCreatorName] = useState("");
    const [modalTheme, setModalTheme] = useState("green-theme");
    const [modalBtnColor, setModalBtnColor] = useState("modalBtnGreen");
    const { availableBalance, fetchUserdata, user } = useAuth()
    const [betLoading, setBetLoading] = useState(false)
    const [answer, setAnswer] = useState("yes");
    const [marketQuestion, setMarketQuestion] = useState();
    const [isValid, setIsValid] = useState(true);
    const handleChangeModal = (event) => {
        setModalPrice(Number(event.target.value));
    };
    const [modalPrice, setModalPrice] = useState(1);
    const [rangePrice, setRangePrice] = useState(1);
    const [error, setError] = useState('');
    const [allComments, setAllComments] = useState([])
    const [creatorId, setCreatorId] = useState("");
    const [group, setGroup] = useState("");
    const [marketData, setMarketData] = useState([]);
    const [tradeData, settradeData] = useState([]);
    const [userTradeData, setUserTradeData] = useState([]);
    const [yesAnswer, setYesAnswer] = useState([]);
    const [noAnswer, setNoAnswer] = useState([]);
    const [data, setChartData] = useState([]);
    const [date, setCloseDate] = useState("");
    const [marketResult, setMarketResults] = useState("");
    const [resultDate, setResultDate] = useState("");
    const [betStatus, setBetStatus] = useState("");
    const [profilePicture, setProfilePicture] = useState('/public/images/dummy-avatar-png.png');
    const [characterLimit, setCharacterLimit] = useState(500);
    const [showModal, setShowModal] = useState(false);



    // ^^^^^^^^^^^^^^^^^^^^^^^^Get All Market Detail^^^^^^^^^^^^^^^^^^^^^^^^


    // ^^^^^^^^^^^^^^^^^^^^^^^^Get Perticalar Market Detail^^^^^^^^^^^^^^^^^^^^^^^^
    useEffect(() => {

        getMarketDetails()

    }, [_id]);


    const FirstBetApi = () => {
        axiosInstance.patch(ApiEndpoints.auth.First_Bet)
        .then(response => {})
        setTimeout(() => {
            fetchUserdata();
        }, 500)
    }

    useEffect(() => {
        if (user?.firstBet === true) {
            setShowModal(true);
        }
    }, [user?.firstBet]);
    const handleClose = () => {
        FirstBetApi();
        setShowModal(false);
    };

    async function getMarketDetails() {
        try {
            const response = await axiosInstance.get(ApiEndpoints.market.getMarketDetails(_id), {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setGroup(response.data.addToGroup)
            setMarketQuestion(response.data._id);
            setQuestion(response.data.question);
            setMarketImage(response.data.marketImage);
            setProfileImage(response.data.createdBy.profile_picture);
            setDescription(response.data.description);
            setAnswerType(response.data.answerType);
            setChoices(response.data.choices);
            setCreatorId(response.data.createdBy._id);
            setYesAnswer(response.data.updatedMarket.yesAnswerProbability);
            setNoAnswer(response.data.updatedMarket.noAnswerProbability);
            setCloseDate(response.data.date);
            setMarketResults(response.data.bet_result);
            setBetStatus(response.data.bet_status);
            setResultDate(response.data.updatedAt)
            if (response.data.createdBy.firstName == null || response.data.createdBy.lastName == null) {
                setCreatorName("User");
            } else {
                setCreatorName(response.data.createdBy.firstName + " " + response.data.createdBy.lastName);
            }
            getAllComment(response.data._id);
            bettingUser(response.data._id);
            tradeList(response.data._id);
            userTradeList(response.data._id);
            chartData(response.data._id)
        } catch (error) {
            return error;
        }
    }
    useEffect(() => {
        async function makeRequest(group) {
            try {
                const response = await axiosInstance.get(ApiEndpoints.market.GET_ALL_MARKET, { params: { group_name: group } });
                setAllQuestion(response.data.markets.filter(market => market.bet_status !== "close" && market.bet_status !== "distribution started" && market.bet_status !== "distributed" && market.bet_status !== "resolved"));
            } catch (error) {
                return error;
            }
        }
        makeRequest(group);
    }, [group]);
    async function getAllComment(id) {
        try {
            const response = await axiosInstance.get(ApiEndpoints.comment.getQuestionById(id));
            setAllComments(response.data.question);
        } catch (error) {
        }
    }

    async function bettingUser(id) {

        try {
            const response = await axiosInstance.get(ApiEndpoints.betting.userBet(id));
            // setAllComments(response.data.question);
            setMarketData(response.data.user);
        } catch (error) {
        }
    }

    async function tradeList(id) {

        try {
            const response = await axiosInstance.get(ApiEndpoints.betting.TradeList(id));
            settradeData(response.data.bets)
        } catch (error) {
        }
    }
    async function userTradeList(id) {

        try {
            const response = await axiosInstance.get(ApiEndpoints.betting.userTrade(id));
            setUserTradeData(response.data.bets);
        } catch (error) {
        }
    }
    async function chartData(id) {

        try {
            const response = await axiosInstance.get(ApiEndpoints.market.marketDetailChart(id));
            setChartData(response.data.chart)
        } catch (error) {
        }
    }
    const pdata = [
        {
            name: 'Bitcoin',
            high: 13,
            low: 10
        },
        {
            name: 'Tether',
            high: 12,
            low: 9
        },
        {
            name: 'Matic',
            high: 11,
            low: 12
        },
        {
            name: 'Ethereum',
            high: 20,
            low: 9
        },
        {
            name: 'Cardano',
            high: 15,
            low: 14
        },
    ]

    const handleSetActive = (e, newValue) => {
        e?.preventDefault();
        setActive(newValue);
    }

    let arr = [1, 2, 3, 4, 5];

    const modules = {
        toolbar: [
            [{ size: [] }],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }],
            // ["image"],
            ["code"],
            ["link"]
        ]
    };

    const formats = [
        "list",
        // "image",
        "align",
        "size",
        "code",
        "link"
    ];

    useEffect(() => {
        themeClass === "dark" ? setTableTheme("table-dark") : setTableTheme("table-light");
    }, [themeClass]);

    // Open first tab
    useEffect(() => {
        // Function to simulate a click event on the "Portfolio" tab link
        const openNotificationsTab = () => {
            const defaultOpenLink = document.getElementById("default-open");
            if (defaultOpenLink) {
                defaultOpenLink.click();
            }
        };

        // Call the function to open the "Portfolio" tab when the component mounts
        openNotificationsTab();
    }, []);

    // Modal box
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleKeyPress = (event) => {
        const isValidInput = /^[0-9]+$/.test(event.key);

        if (!isValidInput) {
            event.preventDefault();
        }
    };
    const handleClick = (event) => {
        setModalPrice(modalPrice + 1)
        setRangePrice(rangePrice + 1)
    }
    const betPlaced = async () => {
        const payload = {
            question: marketQuestion,
            amount: rangePrice,
            quantity: rangePrice,
            unitPrice: rangePrice,
            answer: answer
        }
        setBetLoading(true);
        axiosInstance
            .post(ApiEndpoints.betting.BET, payload)
            .then((response) => {
                getMarketDetails();
                const notify = () => {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
                setIsModalOpen(false);
                fetchUserdata();

            })
            .catch((error) => {
                const notify = () => {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
            })
            .finally(() => {
                setBetLoading(false);
            })
    }


    const handleEditorChange = (html) => {
        if (!value.trim()) {
            setIsValid(false);
        } else {
            setIsValid(true);
            createQuestion()
        }
    };

    const handleKeyDown = (event) => {
        // Check if the character limit has been reached
        if (value.length >= characterLimit && event.key !== 'Backspace') {
            event.preventDefault(); // Prevent the character from being inserted
        }
    };
    const handleContentChange = (content, delta, source, editor) => {
        setIsValid(true);
        setValue(content);
    };


    const createQuestion = () => {
        const payload = {
            "question": marketQuestion,
            "text": value
        }
        // setLoading(true);
        axiosInstance
            .post(ApiEndpoints.market.question, payload)
            .then((response) => {
                const notify = () => {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
                setValue('')
                getAllComment(marketQuestion)
            })

            .catch((error) => {
                const notify = () => {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
            })
            .finally(() => {
                // setLoading(false);
            })
    }
    const removeHTMLTags = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText;
    };
    useEffect(() => {
        async function makeRequest() {
            try {
                const response = await axiosInstance.get(ApiEndpoints.auth.PROFILE_DATA);
                setProfilePicture(response.data.data.profile_picture);
                fetchUserdata();
            } catch (error) {
            }
        }
        makeRequest();
    }, []);
    const CustomTooltip = ({ payload, label, active }) => {
        if (active && payload && payload.length) {
          return (
            <div style={{ backgroundColor: 'yellow', borderRadius: '10px', padding: '5px' }}>
              <p>{`${label}`}</p>
              <p>{`Yes: ${payload[0].value}%`}</p>
            </div>
          );
        }
      
        return null;
      };
    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-start">
                {/* ---------------------Column 1--------------------- */}
                <LeftSidePanel />
                {/* ---------------------Column 2--------------------- */}
                <div className="col-md-6">
                    {/* <nav className="navbar navbar-expand-md mt-3 mb-4">
                        <Link to='/dashboard-market'>
                            <button className='mr-4' style={{ border: 'none', width: 35, height: 35, borderRadius: 30 }}>
                                <img src='/images/icon_back.svg' alt="back" />
                            </button>
                        </Link>
                    </nav> */}
                    <div className='question-section mb-4'>
                        <img src={marketImage === "null" ? "/images/dummy.svg" : marketImage} style={{ width: '100%', height: '100%', objectFit: "cover", borderRadius: "6px" }} alt="question" />
                        <div className="question-profile d-flex align-items-center justify-content-start gap-2">
                            <Avatar
                                src={profileImage || "/images/photo.png"} alt="profile"
                                sx={{ padding: "0px" }}
                            />
                            <Link to={`/dashboard-other-profile/${creatorId}`}>
                                <div>{creatorName}</div>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex  justify-content-between mb-2">
                        <div className="question-label-detail " style={{ wordBreak: "break-word" }}>{question} </div>
                        <div className="py-1 px-3" style={{ backgroundColor: "#E8E8E8", borderRadius: "100px", color: "black", height: "40px", display: "flex", alignItems: "center" }}> {group}</div>
                    </div>
                    <div className="activity-stats d-flex align-items-center justify-content-start gap-3">

                        <div className="close-market-label d-flex gap-2">
                            <img src="/images/icon_clock.svg" alt="" />
                            <div>{moment(date).format('ll')}</div>
                        </div>
                        <div>
                            <div className="chance-wrapper"><span style={{ color: "#069514" }}>{yesAnswer} </span><span style={{ color: "#828282" }}>Yes</span></div>
                        </div>
                        <div>
                            <div className="chance-wrapper"><span style={{ color: "#ff0000" }}>{noAnswer} </span><span style={{ color: "#828282" }}>No</span></div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <ResponsiveContainer width="100%" aspect={2}>
                            <LineChart data={data} width={300} height={400} margin={{
                                top: 5, right: 30, left: 0, bottom: 5
                            }}>
                                <CartesianGrid strokeDasharray={"3 3"} />
                                <XAxis dataKey="name" interval={"preserveStartEnd"} />
                                <YAxis domain={[0, 100]} tickFormatter={(tick) => `${tick}%`} />
                                <Tooltip content={<CustomTooltip />} />
                                <Line type="step" dataKey="Yes" stroke="green" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                        <div className="bet-btn-wrapper col-md-12 d-flex align-items-center justify-content-center px-5">
                            {marketResult !== "pending" || betStatus === "close" ?
                                (<><div className="d-flex align-items-center justify-content-around gap-2" style={{ fontSize: "15px" }}>
                                    {marketResult === "pending" ? (<>  <div style={{ backgroundColor: "#E8E8E8", borderRadius: "100px", width: "150px", display: "flex", alignItems: "center", padding: "3px", justifyContent: "center", color: "black" }}>Result Pending</div></>)
                                        : (<> <div style={{ backgroundColor: "#E8E8E8", borderRadius: "100px", width: "150px", display: "flex", alignItems: "center", padding: "3px", justifyContent: "center", color: "black" }}>Result Declared</div></>)}
                                    <div className={marketResult === 'yes' ? 'b-yes' : 'b-no'}>
                                        {marketResult?.toUpperCase()}
                                    </div>
                                    <div className="close-market-label d-flex gap-1" style={{ width: "130px" }}>
                                        <img src="/images/icon_clock.svg" alt="" style={{ width: "13px" }} />
                                        <span style={{ fontSize: "16px" }}>{moment(resultDate).format('ll')}</span>
                                    </div>

                                </div>
                                </>) : (<>   <div className="d-flex align-items-center justify-content-around gap-4">
                                    {
                                        answerType === "YES/NO"
                                            ?
                                            <>
                                                <div className='b-yes' onClick={handleOpenModal}>Bet YES &uarr;</div>
                                                <div className='b-no' onClick={handleOpenModal}>Bet NO &darr;</div>
                                            </>
                                            :
                                            <div className="multiple-options">
                                                {
                                                    choices.map((option) => (
                                                        <button style={{ height: "100%" }} type="button" className="col-md-6 multiple-options-btn mx-2 my-3"> {option}</button>
                                                    ))
                                                }
                                            </div>
                                    }
                                </div></>)}

                        </div>
                    </div>
                    <div className="question-description" style={{ wordBreak: "break-word" }}>
                        {parse(description)}
                    </div>
                    <div className="collapse-container px-0">
                        <div className="collapse-wrapper d-flex gap-3 flex-row">
                            <div className="container">
                                <ul className="toggle-menu-items nav nav-pills d-flex justify-content-between gap-2">
                                    <li onClick={(e) => handleSetActive(e, "comments")}
                                        className={active === "comments" ? 'custom-tab' : 'InActive'}>
                                        <a className='m-2' data-toggle="pill" href="#comments" id="default-open">Comments</a>
                                    </li>
                                    <li onClick={(e) => handleSetActive(e, "positions")}
                                        className={active === "positions" ? 'custom-tab' : 'InActive'}>
                                        <a className='m-2' data-toggle="pill" href="#position">Positions</a>
                                    </li>
                                    <li onClick={(e) => handleSetActive(e, "trades")}
                                        className={active === "trades" ? 'custom-tab' : 'InActive'}>
                                        <a className='m-2' data-toggle="pill" href="#trades">Trades</a>
                                    </li>
                                    <li onClick={(e) => handleSetActive(e, "yourTrades")}
                                        className={active === "yourTrades" ? 'custom-tab' : 'InActive'}>
                                        <a className='m-2' data-toggle="pill" href="#yourTrades">Your Trades</a>
                                    </li>
                                </ul>
                                <hr />
                                <div className="tab-content">
                                    <div id="comments" className="tab-pane fade gap-2">
                                        <div className="d-flex flex-row align-items-start gap-2 ">
                                            <Avatar className="market-user-img" src={profilePicture || "/images/photo.png"} alt="profile" />
                                            <div className="flex-grow-1" style={{ wordBreak: "break-word" }}>
                                                <ReactQuill
                                                    theme="snow"
                                                    modules={modules}
                                                    formats={formats}
                                                    value={value}
                                                    onChange={handleContentChange}
                                                    onKeyDown={handleKeyDown}
                                                    placeholder='Optional. Provide background info and market resolution criteria here.'
                                                    className='rte-placeholder'
                                                    require
                                                />

                                            </div>
                                        </div>
                                        <div style={{ margin: '10px 0px', position: 'relative', left: '40px' }}>
                                            {!isValid && <div style={{ color: 'red' }}>Content is required</div>}
                                        </div>
                                        <div className="flex justify-content-end mt-2">
                                            <img className="sendBtn" src="/images/icon_send.svg" alt="send" onClick={handleEditorChange} />
                                        </div>
                                        <div style={{ marginTop: "50px" }}>
                                            {allComments.map((item, index) => (
                                                <>
                                                    <div className='d-flex align-items-start mb-2 row' key={index}>
                                                        <div className='d-flex gap-2 col-12'>
                                                            <div className='d-flex align-items-start'>
                                                                <Avatar src={item.profile_picture || '/images/profile_image.svg'} alt='' />
                                                            </div>
                                                            <div className='d-flex align-items-start flex-column'>
                                                                {/* <div style={{ color: '#808080' }}>
                                                                    {item.question && <span>{item.question}</span>}
                                                                </div> */}
                                                                <div style={{ wordBreak: "break-word" }}>
                                                                    <span className='comment-username'>@{item.name}</span>&nbsp;
                                                                    <span>{removeHTMLTags(item.text)}</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{ color: '#000000' }}>
                                                                        Reply :
                                                                    </span>
                                                                    {item.answer && <span style={{ display: 'block', color: '#808080',wordBreak:"break-word" }}>{item.answer}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12' style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <Chip label={formatDistanceToNow(new Date(item.createdAt), { addSuffix: true, includeSeconds: true }).replace('about ', '')}></Chip>

                                                        </div>
                                                    </div>

                                                </>
                                            ))}
                                        </div>
                                    </div>

                                    <div id="position" className="tab-pane fade">
                                        {/* <div className="d-flex align-items-center justify-content-end gap-3 mb-4">
                                            <div>Sort By</div>
                                            <div className="form-group"
                                                data-bs-theme={themeClass === "dark" ? "dark" : "light"}
                                                style={{ margin: 0 }}>
                                                <select
                                                    className={`${themeClass === 'dark'
                                                        ? "option-theme-dark form-control"
                                                        : "option-theme-light form-control"
                                                        }`}
                                                    id="exampleFormControlSelect1">

                                                    <option>position</option>
                                                    <option>profit</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="flex justify-content-center mb-4" style={{ fontSize: "18px" }}>
                                            <div className="d-flex col-md-6 align-items-end justify-content-end p-0">
                                                <div>{marketData?.yesAnswer?.totalQuantity} <span style={{ color: "#069514" }}> YES </span> Payouts</div>
                                            </div>
                                            <div className="d-flex col-md-6 align-items-end justify-content-end p-0">
                                                <div>{marketData?.noAnswer?.totalQuantity}<span style={{ color: "#EB5757" }}> NO </span> Payouts</div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-3 py-2">
                                            {/* col 1 */}
                                            <div className="flex-grow-1">
                                                {marketData?.yesAnswer?.users?.map((user, index) => (
                                                    <div key={index} className="market-bet-position flex flex-grow-1 justify-content-between py-2 px-2 mb-2">
                                                        <div className="flex justify-content-between gap-2">
                                                            <Avatar src={user.profile_picture} alt="" />
                                                            <div>{user.name}</div>
                                                        </div>
                                                        <span style={{ color: "#069514" }}>
                                                            ${user.amount}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* col 2 */}
                                            <div className="flex-grow-1">
                                                {marketData?.noAnswer?.users?.map((user, index) => (
                                                    <div key={index} className="market-bet-position flex flex-grow-1 justify-content-between py-2 px-2 mb-2">
                                                        <div className="flex justify-content-between gap-2">
                                                            <Avatar src={user.profile_picture || "/images/photo.png"} alt="" />
                                                            <div>{user.name}</div>
                                                        </div>
                                                        <span style={{ color: "#069514" }}>
                                                            ${user.amount}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div id="trades" className="tab-pane fade">
                                        {tradeData?.map((item, index) => {
                                            return (
                                                <div className="d-flex justify-content-between mb-4">

                                                    <div className="d-flex align-items-center gap-2">
                                                        <Avatar src={item.user.profile_picture || "/images/photo.png"} alt="" />
                                                        <p>{item.user.firstName}  ${item.amount}
                                                            <span style={{ color: "#EB5757", textTransform: "capitalize" }}>{item.answer.toUpperCase()}</span>
                                                        </p>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <span>{formatDistanceToNow(new Date(item.createdAt), { addSuffix: true, includeSeconds: true }).replace('about ', '')}</span>
                                                        {/* <img className="replyBtn" src="/images/Vector.svg" alt="reply" /> */}
                                                    </div>
                                                </div>
                                            )
                                        })}


                                        {/* <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="market-user-img" src="/images/photo.png" alt="" />
                                                <p>Chris Canas bought ₮19
                                                    <span style={{ color: "#069514" }}> YES </span>
                                                    at 15%
                                                </p>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <div>16d ago</div>
                                                <img className="replyBtn" src="/images/Vector.svg" alt="reply" />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div id="yourTrades" className="tab-pane fade">
                                        <div className="table-responsive">
                                            <table className={`${tableTheme} table`}>
                                                <thead>
                                                    <tr>
                                                        <th>Bet</th>
                                                        <th>Amount</th>
                                                        <th>Quantity</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userTradeData.map((trade) => (
                                                        <tr key={trade._id}>
                                                            <td>{<span style={{ color: trade.answer === 'yes' ? "#069514" : "#EB5757" }}>{trade.answer.toUpperCase()}</span>}</td>
                                                            <td>${trade.amount}</td>
                                                            <td>{trade.quantity}</td>
                                                            <td>{moment(trade.createdAt).fromNow()}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------Column 3--------------------- */}
                <div className="mt-5 mb-4 col-md-3 d-flex align-items-center justify-content-center flex-column" style={{ height: '100%' }}>

                    <div className='mb-4'>Related Market</div>
                    {
                        allQuestion?.map((market) => (
                            <RelatedMarketCard key={market._id} market={market} />
                        ))
                    }
                </div>
                {/* Modal start*/}
                {isModalOpen && (
                    <div className="modal">
                        <div className={`${themeClass === "dark"
                            ? "text-white modal-content bg-dark"
                            : "modal-content"}`}>
                            <span className="modal-close"
                                onClick={handleCloseModal}>

                                <img src="/images/cross.png" alt="close" style={{ width: "40px" }} />
                            </span>
                            {/* <h4 style={{ width: "90%" }}>{props.market.question}</h4> */}
                            <div className="d-flex justify-content-center gap-4 my-3">
                                <div className={`${modalTheme === "green-theme"
                                    ? "modal-bet-yes"
                                    : "modalBetYesToggle"
                                    }`}
                                    onClick={() => {
                                        setModalBtnColor("modalBtnGreen");
                                        setModalTheme("green-theme");
                                        setAnswer("yes");

                                    }}>Bet YES &uarr;</div>
                                <div className={`${modalTheme === "red-theme"
                                    ? "modal-bet-no"
                                    : "modalBetNoToggle"
                                    }`}
                                    onClick={() => {
                                        setModalBtnColor("modalBtnRed");
                                        setModalTheme("red-theme");
                                        setAnswer("no");
                                    }}
                                >Bet NO &darr;</div>
                            </div>
                            <div className={`${themeClass === 'dark'
                                ? `${modalTheme} bet-amount-section d-flex rounded col-md-12 text-white bg-dark`
                                : `${modalTheme} bet-amount-section d-flex rounded col-md-12`
                                }`}>

                                <div className="col-md-4">
                                    <div className="mb-2">Amount</div>
                                    <div className="d-flex flex-column">
                                        <div className="modal-input-container form-outline d-flex justify-content-start align-items-center my-3" style={{
                                            border: '1px solid #d2d2d2',
                                            borderRadius: '6px'
                                        }}>
                                            <div className='ml-2 pr-2'>$</div>
                                            <input type="text"
                                                className="bet-modal-input border-0"
                                                onChange={handleChangeModal}
                                                onKeyDown={handleKeyPress}
                                                placeholder="Enter value"
                                                value={modalPrice}
                                                readOnly="true"
                                            />
                                            <div
                                                className="increase-amount"
                                                style={{ color: "#069514" }}
                                                onClick={handleClick}
                                            >+{rangePrice}</div>
                                        </div>
                                        <div> YES</div>
                                        <div>{yesAnswer}</div>
                                    </div>
                                </div>
                                <div className="col-md-8 pl-3">
                                    <div className="flex justify-content-end mb-4">
                                        <div><span style={{ color: "#828282", textAlign: "right" }}>Balance</span> ${availableBalance}</div>
                                    </div>
                                    <div className="range mb-3">
                                        <input type="range"
                                            className="form-range"
                                            min="1"
                                            max="100"
                                            value={rangePrice}
                                            readOnly
                                            onChange={(e) => {
                                                setModalPrice(Number(e.target.value))
                                                setRangePrice(Number(e.target.value))
                                            }}
                                            step="1"
                                            id="customRange3"
                                        />
                                    </div>
                                    ${rangePrice}
                                    <div>No</div>
                                    <div>{noAnswer}</div>
                                </div>
                            </div>
                            <div className="flex justify-content-center">
                                {/* <button className={`${modalBtnColor} modal-btn`} onClick={betPlaced} loading={loading}>Bet ${modalPrice}</button> */}
                                <button className={`${modalBtnColor} modal-btn`} onClick={betPlaced} disabled={betLoading}>
                                    {betLoading ? (<div class="spinner-border" role="status" />) : `Bet ${modalPrice}`}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {/* Modal end*/}
                {/* spacer */}
                {/* ------------------------------------------- */}
                <section style={{ paddingBlock: '80px' }}></section>
                {/* ------------------------------------------- */}
                <ToastContainer />
                {showModal && (
                <div className="modal fade bd-example-modal-lg show" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content" style={{ width: 'auto' }}>
                            <div className="modal-header">
                                <h5>How to place a Bet</h5>
                                <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div style={{ padding: '20px', textAlign: 'center' }}>

                                    <p>Follow the steps in this video to place a bet easily and securely.</p>
                                </div>
                                <div style={{ display: "flex", height: "60vh" }}>
                                    <iframe
                                        src="../videos/Bet.mp4"
                                        style={{ flex: 1, width: '100%', height: '100%' }}
                                        // frameBorder="0"
                                        allowFullScreen
                                        title="How to Connect a Wallet"
                                    />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div >
    )
}

export default MarketDetail