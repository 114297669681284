import React from 'react'
import './styles/VerifyReset.css'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './Components/Navbar'
import { axiosInstance } from './network/config/adapter'
import { ApiEndpoints } from './network/config/endpoints'

const Verify = () => {

    const objRef = useRef(null);
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const resetEmail = localStorage.getItem('resetEmail');

    useEffect(() => {
        const obj = objRef.current;
        obj.addEventListener('keydown', stopCaret);
        obj.addEventListener('keyup', stopCaret);

        return () => {
            obj.removeEventListener('keydown', stopCaret);
            obj.removeEventListener('keyup', stopCaret);
        };
    }, []);

    function stopCaret() {
        if (inputValue.length > 3) {
            setCaretPosition(objRef.current, 3);
        }
    }

    function setCaretPosition(elem, caretPos) {
        if (elem != null) {
            if (elem.createTextRange) {
                var range = elem.createTextRange();
                range.move('character', caretPos);
                range.select();
            } else {
                if (elem.selectionStart) {
                    elem.focus();
                    elem.setSelectionRange(caretPos, caretPos);
                } else {
                    elem.focus();
                }
            }
        }
    }


    //API CALL
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create a data object with the form values
        const data = {
            otp: inputValue,
            email: resetEmail

        };


        try {
            // Make a POST request using Axios
            const response = await axiosInstance.post(ApiEndpoints.auth.VERIFY_ACCOUNT,  data );

            // Handle the response (e.g., show a success message)

            if (response.status === 200) {
                navigate('/reset-password');
            }

        } catch (error) {
            // Handle the error (e.g., show an error message)
            return error;

            const notify = () => {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            };
            notify();
        }

    };

    // RESEND api call
    const handleResend = async (e) => {

        // Create a data object with the form values
        const resendData = {
            email: resetEmail
        };
        try {

            const response = await axiosInstance.post(ApiEndpoints.auth.FORGOT_PASSWORD, resendData);

            // Handle the response (e.g., show a success message)

            if (response.status === 200) {
                // navigate('/signin');
            }

        } catch (error) {
            // Handle the error (e.g., show an error message)
           

            const notify = () => {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            };
            notify();
        }
    };

    return (
        <>
            <div style={{ height: "100vh" }}>
                <Navbar />
                <div className='verify-wrapper d-flex align-items-center justify-content-center'>
                    <section className="main-container">
                        <div className="container">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col">
                                    <div className="card card-registration my-4 border-0">
                                        <div className="row g-0">
                                            <img src="images/img_verify.svg"
                                                alt="Sample photo" className="img-fluid center-block col-xl-6 px-5"
                                            />
                                            <div className="col-xl-6" style={{ boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.12)' }}>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="card-body mx-5 text-black">
                                                        <h3 className="mb-2 label verify-label">Verify Account</h3>
                                                        <p className="mb-3 text-dark">Please Enter the 4 digit code sent to <span className='py-2 text-dark'>{resetEmail}</span></p>

                                                        <div className="form-outline mb-3 d-flex align-items-center justify-content-center">

                                                            <div className='my-3 mb-4' id="divOuter">
                                                                <div id="divInner">
                                                                    <input id="partitioned"
                                                                        ref={objRef}
                                                                        value={inputValue}
                                                                        onChange={(e) => setInputValue(e.target.value)}
                                                                        maxLength="4"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-outline d-flex justify-content-end align-items-end">
                                                            <p><a href='#' className='forgot-text' onClick={() => { handleResend() }}>Resend code</a></p>
                                                        </div>

                                                        <div className='d-flex flex-column'>
                                                            <button type="submit" className="col-xl-12 btn btn-lg" style={{ backgroundColor: '#069514', border: 'none', color: '#fff', borderRadius: 30 }}>Verify</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className='outer-container'>
                                                    <div className='back-icon inner-container d-flex justify-content-center align-items-center'>
                                                        <Link to='/signin'>
                                                            <img src='./images/icon_back.svg' /><span className='back-text mx-2'> Back to Previous Page</span>
                                                        </Link>
                                                    </div>
                                                    <ToastContainer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Verify