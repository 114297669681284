import React, { useState } from 'react'
import './styles/Forgot.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Components/Navbar';
import { axiosInstance } from './network/config/adapter';
import { ApiEndpoints } from './network/config/endpoints';

const ForgotPassword = () => {

    const [resetEmail, setResetEmail] = useState('');
    const navigate = useNavigate();
    // const localResetEmail = localStorage.setItem('resetEmail', resetEmail);

    const handleResendPassword = async (e) => {
        e.preventDefault();

        // Create a data object with the form values
        const data = {
            email: resetEmail
        };


        try {

            localStorage.setItem('resetEmail', resetEmail);

            const response = await axiosInstance.post(ApiEndpoints.auth.FORGOT_PASSWORD, data);

            // Handle the response (e.g., show a success message)
            const notify = () => {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            };
            notify();

            setTimeout(() => {
                navigate('/verify-reset');
            }, 1000)

        } catch (error) {

            let errorMessage = '';


            if (error.response.status === 404) {
                errorMessage = 'Invalid email address'
            }

            // Handle the error (e.g., show an error message)

            const notifyError = () => {
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            };
            notifyError();
        }
    };

    return (

        <>
            <div style={{ height: "100vh" }}>
                <Navbar />
                <div className='wrapper d-flex align-items-center justify-content-center'>
                    <section className="main-container">
                        <div className="container">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col">
                                    <div className="card card-registration my-4 border-0">
                                        <div className="row g-0">
                                            <img src="images/img_mail.svg"
                                                alt="Sample photo" className="img-fluid center-block col-xl-6 px-5"
                                            />
                                            <div className="col-xl-6" style={{ boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.12)' }}>
                                                <form onSubmit={handleResendPassword}>
                                                    <div className="card-body mx-5 text-black">
                                                        <h3 className="mb-2 label forgot-label">Forgot Password</h3>
                                                        <p className="mb-3 text-dark">Enter the email address associated with your account</p>

                                                        <div className="form-outline mb-3">
                                                            <label className="forgot-email-color" for="form3Example97">Email</label>
                                                            <input style={{ outline: 0, borderWidth: '0 0 1px' }} type="email" id="form3Example97" className="form-control form-control-sm shadow-none"
                                                                value={resetEmail}
                                                                onChange={(e) => setResetEmail(e.target.value)}
                                                                required
                                                            />
                                                        </div>

                                                        <div className='d-flex flex-column'>
                                                            <button type="submit" className="col-xl-12 btn btn-lg" style={{ backgroundColor: '#069514', border: 'none', color: '#fff', borderRadius: 30 }}>Send Password Reset OTP</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* <div className='back-icon d-flex justify-content-center align-items-center'>
                                            <Link to='/signin'>
                                                <img src='./images/icon_back.svg' /><span className='forgot-text'> Back to log in</span>
                                            </Link>
                                        </div> */}

                                                <div className='outer-container'>
                                                    <div className='back-icon inner-container d-flex justify-content-center align-items-center'>
                                                        <Link to='/signin'>
                                                            <img src='./images/icon_back.svg' /><span className='back-text'> Back to log in</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <ToastContainer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>

    )
}

export default ForgotPassword