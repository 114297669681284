import { useEffect } from "react";
import { Link } from "react-router-dom"

function HowToCreateWallet() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container" style={{ paddingBlock: "20px", width: "100%", display: 'flex', flexDirection: 'column' }}>
            <Link to={-1}>
                <button className="mr-4" style={{ border: "none", width: "35px", height: "35px", borderRadius: "30px" }}>
                    <img src="./images/icon_back.svg" alt="back" />
                </button>
            </Link>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <h3>How to Connect a Wallet</h3>
                <p>Follow the steps in this video to connect your wallet easily and securely.</p>
            </div>
            <div style={{ display: "flex", height: "80vh" }}>
                <iframe
                    src="../videos/How_to_create_wallet.mp4"
                    style={{ flex: 1, width: '100%', height: '100%' }}
                    // frameBorder="0"
                    allowFullScreen
                    title="How to Connect a Wallet"
                />
            </div>
            <div style={{ padding: '20px', display: "flex", flexDirection: "column", gap: 20 }}>
                <p>0:00: In this video, I'm going to show you how to set up your wallet step by step so that you can start trading with real money.</p>
                <p>0:06: Hi, I'm Nick from Smarter Bet. This video will guide you through the process to set up your wallet so you can start trading on the outcome of events.</p>
                <p>0:14: Let's get started.</p>
                <p>0:16: The first thing you need to do when setting up your wallet is to click connect wallet on the home page.</p>
                <p>0:21: Once you click several options will appear, select MetaMask and then click the don't have a MetaMask button at the bottom of the page.</p>
                <p>0:29: You will now be prompted to download MetaMask from the Chrome Web Store.</p>
                <p>0:33: Click on the add to Chrome button.</p>
                <p>0:35: Once you click, add to Chrome, MetaMask will be downloaded and added as one of your browser extensions.</p>
                <p>0:40: It should automatically open in a new tab where you can follow the prompts to create your MetaMask account. To easily access MetaMask in the future, pin it to your browser extensions bar as shown. Now that you've created an account with MetaMask,</p>
                <p>1:07: It's time to connect your MetaMask wallet to your Smarter Bet account. To do this,</p>
                <p>1:11: Go back to your Smarter Bet home page, refresh the page and then press the connect wallet button again.</p>
                <p>1:16: The MetaMask extension will open asking for permission to connect MetaMask to your Smarter Bet wallet.</p>
                <p>1:23: Click next and then confirm. Your MetaMask should now be connected to your Smarter Bet wallet.</p>
                <p>1:29: Having connected the MetaMask wallet to your Smarter Bet account, you can now fund your account.</p>
                <p>1:34: Click on the MetaMask extension tab and in the pop-up page press import token. Type in USDT and select it to import it into your MetaMask wallet.</p>
                <p>1:46: Since USDT will be the currency that Smarter Bet uses.</p>
                <p>1:49: Once you have imported USDT, click on USDT and then click the buy and sell button.</p>
                <p>1:55: This will take you to MetaMask's portfolio page where you can enter the amount of money you would like to buy in USDT to deposit into your MetaMask wallet.</p>
                <p>2:04: Make sure to connect your wallet in the MetaMask portfolio as well as select Tether USDT as the token and choose your preferred payment method to carry out the deposit.</p>
                <p>2:18: Please be aware that MetaMask charges gas fees for every transaction you make.</p>
                <p>2:24: Now with the deposited amount, go back to the Smarter Bet home page, click the MetaMask wallet extension, press the send button and copy the deposit address from the Smarter Bet home page, paste it into the send token page.</p>
                <p>2:41: Enter the amount you wish to deposit and the required Ethereum amount will be displayed as highlighted you need to purchase to carry out the transaction.</p>
                <p>2:50: So go back to the main MetaMask wallet page.</p>
                <p>2:53: Click Ethereum, press the buy and sell button and purchase the required ETH. Once you have the required Ethereum, return to the MetaMask wallet on the Smarter Bet home page, click USDT in the token page.</p>
                <p>3:12: Press send and paste the deposit address again.</p>
                <p>3:20: Enter the amount you want to deposit in USDT, press next, then confirm to complete the transaction and deposit the amount into your Smarter Bet wallet. There you have it in just a few simple steps.</p>
                <p>3:32: You will now have your deposited amount reflected in your wallet amount.</p>
                <p>3:36: If you have any concerns or questions, feel free to reach out to us on the Contact Us page and I would be happy to address it.</p>
                <p>3:43: Thanks for watching.</p>
            </div>
        </div>

    )
}
export default HowToCreateWallet