import React from 'react'
import { useState } from 'react'
import './styles/ResetPassword.css'
import showPwdImg from './show-password.svg';
import hidePwdImg from './hide-password.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Components/Navbar';
import { axiosInstance } from './network/config/adapter';
import { ApiEndpoints } from './network/config/endpoints';


const ResetPassword = () => {

    const [isRevealPwd, setIsRevealPwd] = useState(true);
    const [isConfirmPwd, setIsConfirmPwd] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const email = localStorage.getItem('resetEmail');
        // Create a data object with the form values
        const data = {
            email: email,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
        };


        try {
            // Make a POST request using Axios
            const response = await axiosInstance.post(ApiEndpoints.auth.RESET_PASSWORD, data);

            // Handle the response (e.g., show a success message)
            navigate('/password-reset-successfully');
        } catch (error) {
            // Handle the error (e.g., show an error message)

            const notify = () => {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            };
            notify();
        }
    };

    return (
        <>
            <div style={{ minHeight: '100vh' }}>
                <Navbar />
                <section className="d-flex align-items-center justify-content-center main-container" style={{}}>
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col">
                                <div className="card card-registration my-4 border-0">
                                    <div className="row g-0">
                                        <img src="images/img_lock.svg"
                                            alt="reset" className="reset-password-img img-fluid center-block col-xl-6 px-5"
                                        />
                                        <div className="col-xl-6" style={{ boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.12)' }}>
                                            <form onSubmit={handleSubmit}>
                                                <div className="card-body mx-5 text-black">
                                                    <h3 className="mb-2 label reset-password-label">Reset Password</h3>
                                                    <p className="mb-3 text-dark">Your new password must be different from
                                                        previously used password.</p>

                                                    <div className="form-outline mb-2">
                                                        <label className="reset-password-label" for="form3Example97">New Password</label>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                                type={isRevealPwd ? "password" : "text"}
                                                                id="form3Example97"
                                                                className="form-control form-control-sm shadow-none"
                                                                value={newPassword}
                                                                onChange={(e) => setNewPassword(e.target.value)}
                                                                required
                                                            />
                                                            <img className='pwd-container-img'
                                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                                alt=''
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-outline mb-2">
                                                        <label className="reset-password-label" for="form3Example97">Confirm Password</label>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <input style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                                type={isConfirmPwd ? "password" : "text"}
                                                                id="form3Example97"
                                                                className="form-control form-control-sm shadow-none"
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                                required
                                                            />

                                                            <img className='pwd-container-img'
                                                                title={isConfirmPwd ? "Hide password" : "Show password"}
                                                                src={isConfirmPwd ? hidePwdImg : showPwdImg}
                                                                onClick={() => setIsConfirmPwd(prevState => !prevState)}
                                                                alt=''
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='d-flex flex-column'>
                                                        <button type="submit" className="col-xl-12 btn btn-lg mt-5" style={{ backgroundColor: '#069514', border: 'none', color: '#fff', borderRadius: 30 }}>Reset Password</button>
                                                    </div>
                                                    <ToastContainer />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default ResetPassword