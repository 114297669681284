import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { useWeb3Modal } from '@web3modal/wagmi/react'

import { WagmiConfig } from 'wagmi'
import { arbitrum, mainnet } from 'wagmi/chains'
import { axiosInstance } from '../network/config/adapter'
import { ApiEndpoints } from '../network/config/endpoints'
import { Padding } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
// 1. Get projectId
const projectId = 'afeed2ea7e8d4924bf554d95e2d0ef72'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, arbitrum]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })


// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })

export default function App() {


  const { open } = useWeb3Modal()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const updateWalletAddress = (address) => {
    axiosInstance
      .post(ApiEndpoints.wallet.WalletCONNECT, {
        wallet: address,
      })
      .then((response) => {
      }).catch((error) => {
        const notify = () => {
          toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
      };
      notify();
      })
  }
  const connect = async () => {
    try {
      // Assuming that wagmiConfig has the necessary logic to retrieve the user's address
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      const userAddress = accounts[0];
      updateWalletAddress(userAddress);
    } catch (error) {
      console.error('Error getting user address:', error);
    }
  };
  return (

    <WagmiConfig config={wagmiConfig}>
      {/* <button onClick={() => open()}>Open Connect Modal</button>
      <button onClick={() => open({ view: 'Networks' })}>Open Network Modal</button> */}
      {/* <w3m-button onClick={connect} style={{ width: windowWidth <= 600 ? "200px" : "150px" }}/> */}
      <w3m-button onClick={connect} style={{ width:"200px", display:"contents" }}/>
      <ToastContainer />
    </WagmiConfig>
    
  )
}


