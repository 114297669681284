import React from 'react'
import { ThemeContext } from '../Context/ThemeContext';
import { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';


const RelatedMarketCard = (props) => {

    const { themeClass, themeInput } = useContext(ThemeContext);
    const maxLength = 50;
    const truncatedText = props.market.question.length > maxLength
      ? props.market.question.substring(0, maxLength) + '...'
      : props.market.question;
  
    return (

            <div className={`${themeClass === "dark " ? "bg-dark mb-3 p-2" : "mb-3 p-2"}`} style={{ backgroundColor: "#33333347", borderRadius: "10px", width: "250px" }}>
                <div className="d-flex align-items-center justify-content-between gap-3 mb-2">
                <Link to={props.market.createdBy == null ? "" : `/dashboard-other-profile/${props.market.createdBy._id}`}>
                    <div className="d-flex justify-content-between gap-2">
                        <Avatar src={props.market.createdBy?.profile_picture || "/images/Ellipse26(1).svg"} alt="" style={{ width: "30px",height:"30px" }} />
                        <div style={{ color: "rgb(130, 130, 130)" }}>{props.market.createdBy?.firstName || "User"}</div>
                    </div>
                    </Link>
                    {/* <div style={{ color: "#2F80ED" }}>21%</div> */}
                </div>
                <Link to={`/dashboard-market-detail/${props.market._id}`}>
                <div style={{ fontSize: "14px" ,wordBreak:"break-word"}}> {truncatedText}</div>
                </Link>
            </div>
    )
}

export default RelatedMarketCard