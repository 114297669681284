import { useEffect } from "react";
import { Link } from "react-router-dom"

function HowToCompleteKYC() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container" style={{ paddingBlock: "20px", width: "100%", display: 'flex', flexDirection: 'column' }}>
            <Link to={-1}>
                <button className="mr-4" style={{ border: "none", width: "35px", height: "35px", borderRadius: "30px" }}>
                    <img src="./images/icon_back.svg" alt="back" />
                </button>
            </Link>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <h3>How to complete KYC</h3>
                <p>Follow the steps in this video to complete your KYC easily and securely.</p>
            </div>
            <div style={{ display: "flex", height: "80vh" }}>
                <iframe
                    src="../videos/KYC.mp4"
                    style={{ flex: 1, width: '100%', height: '100%' }}
                    // frameBorder="0"
                    allowFullScreen
                    title="How to Connect a Wallet"
                />
            </div>
            <div style={{ padding: '20px', display: "flex", flexDirection: "column", gap: 20 }}>
                <p>0:00: In this video, I'm going to show you how to carry out the verification, also known as K Y C, step by step to ensure you are compliant to trade on the platform.</p>
                <p>0:09: Hi, I'm Nick from Smarter Bet. This video will guide you through the process to verifying yourself so that you can start trading on the outcome of events.</p>
                <p>0:17: Let's get started.</p>
                <p>0:19: The first step to verifying your account is to click your name on the home page.</p>
                <p>0:23: This will open your profile page. Once in the profile page, click the blue K Y C button on the top right side as shown, which will lead to the upload documents section of the profile there.</p>
                <p>0:34: You will click the K Y C button again.</p>
                <p>0:37: This will bring you to a create client pop up page. Here,</p>
                <p>0:40: Go ahead and fill in your date of birth and nationality as shown. To finish,</p>
                <p>0:52: Make sure to select the document type, the classification, and the issuing country of that document to ensure we can verify your identity. Once done,</p>
                <p>1:00: Go ahead and submit a scan of the documents you specified on the create document page.</p>
                <p>1:05: If approved, you should be good to start trading on the outcome of events.</p>
                <p>1:09: If you have any concerns or questions, feel free to reach out to us on the Contact Us page and I would be happy to address it.</p>
                <p>1:16: Thanks for watching.</p>
            </div>
        </div>

    )
}
export default HowToCompleteKYC