import React from 'react'
import Navbar from './Components/Navbar';

const TermsConditions = () => {
    
  return (
    <>
    <Navbar />
    <section className="d-flex align-items-center justify-content-center main-container">
      <div className="py-2">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card-registration border-0">
              <div className="row g-0 d-flex justify-content-center align-items-center">
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-start">
                    <div className="col-md-11">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mt-5 mb-4">
                              <div className="">
                                <nav className="navbar navbar-expand-md mt-3 mb-1 flex justify-content-start">
                                  <h3 className="">Terms & Conditions</h3>
                                </nav>
                                <p className="primary-color about-sub-header mr-4 mb-4">Everything you wanted to know about Markets in one place!</p>
                              </div>
                              <div>
                                <div className="font-weight-bold pb-2">Basics</div>
                                <div className="font-weight-bold">What is Prediction Market?</div>
                                <p className="primary-color">Prediction markets allow you to bet on the outcome of future events.</p>
                                <p className="primary-color">The prices for shares on each outcome can be interpreted as the probability that the underlying event will
                                  happen and track what market participants in aggregate believe.</p>
                                <p className="primary-color">As more people trade on the market, the probability estimate converges to reflect the truth. Since these
                                  probabilities are public, anyone can use them to make more informed decisions.</p>
                                <p className="primary-color">On Manifold, anyone can create their own prediction market about any question they want! We believe
                                  prediction markets are not only the best way to forecast difficult questions, but also a fun way to interact
                                  with friends, build communities over shared interests, and facilitate the sharing of information.</p>

                                <div className="font-weight-bold">An example</div>
                                <p className="primary-color">Consider a prediction market on a question like: "Will Democrats win the 2024 US presidential election?"</p>
                                <p className="primary-color">If the market is currently displaying a probability of 50%, but you believe that the true chance is higher, you
                                  can purchase shares at a cost $0.50/share that will pay out $1 if the Democrats win. Suppose the true
                                  probability is 60%, then you will make $0.10 for each share you purchase in expectation.</p>
                                <p className="primary-color mb-5">Now, you or I could be mistaken and overshooting the true probability one way or another. If so, there's now a
                                  monetary incentive for someone else to bet and correct it! Over time, the implied probability will converge to
                                  the market's best estimate.</p>
                              </div>

                              <div className="flex" style={{ overflow: 'hidden', position: 'relative' }}>
                                <iframe
                                  title="Embedded YouTube Video"
                                  frameBorder="0"
                                  scrolling="no"
                                  marginHeight="0"
                                  marginWidth="0"
                                  width="800"
                                  height="443"
                                  type="text/html"
                                  src="https://www.youtube.com/embed/D0UnqGm_miA?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"
                                ></iframe>
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '0',
                                    right: '0',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    color: '#000',
                                    textAlign: 'center'
                                  }}
                                >
                                  <div style={{ overflow: 'auto', position: 'absolute', height: '0pt', width: '0pt' }}>
                                    Generated by <a href="https://www.embedista.com/embed-youtube-video">Embed Youtube Video</a> online
                                  </div>
                                  <script type="text/javascript" src="https://www.embedista.com/j/ytvideo.js"></script>
                                </div>
                                <style>{`.newst{position:relative;text-align:right;height:420px;width:520px;} #gmap_canvas img{max-width:none!important;background:none!important}`}</style>
                              </div>

                              <div className="mt-5 font-weight-bold">Can prediction markets work without real money?</div>
                              <p className="primary-color">Yes! You can track Manifold's predictive track record in real time from our platform calibration page.</p>
                              <p className="primary-color">Beside our own platform, there is substantial evidence that play-money prediction markets provide real
                                forecasting power. Examples include <span className="sport-betting">sports betting</span> and internal prediction markets at firms like Google.</p>
                              <p className="primary-color">Traders in a play-money environments are competing for the social prestige and sense of progression that
                                come from predicting and trading well.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default TermsConditions