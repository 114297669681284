import React, { useContext, useEffect, useState } from 'react';
import './styles/Home.css';
import { Link } from 'react-router-dom';
import { axiosInstance } from './network/config/adapter';
import { ApiEndpoints } from './network/config/endpoints';
import moment from 'moment';
import { ThemeContext } from './Context/ThemeContext';
import { Container } from 'react-bootstrap';

const Home = () => {

    // **********************************************************
    // Remove email from localStorage 
    localStorage.removeItem('email');
    // **********************************************************
    const [allMarketData, setAllMarketData] = useState([]);
    const [darkMode, setDarkMode] = useState(false);
    const { themeClass } = useContext(ThemeContext);
    useEffect(() => {
        getMarket();

    }, []);
    async function getMarket() {
        try {
            const response = await axiosInstance.get(ApiEndpoints.market.GET_ALL_MARKET);
            setAllMarketData(response.data.markets.filter(market => market.bet_status !== "close" && market.bet_status !== "distribution started" && market.bet_status !== "distributed" && market.bet_status !== "resolved"));
        } catch (error) {
        }
    }
    const [theme, setTheme] = useState(
        localStorage.getItem('theme') || 'Light'
    );
    const toggleThemes = () => {
        if (theme === 'Light') {
            setTheme('Dark');
        } else {
            setTheme('Light');
        }
    };
    return (
        <>
            {/* Navigation section */}

            {/* ------------------------------------------------- */}
            <Container style={{zoom:(0.8)}}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <button className="ml-2 navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* <a className="navbar-brand" href="/#">
                        <img style={{ width:"200px",height:"45px"}} src='./images/Logo1.svg' alt="company logo" />
                    </a> */}
                    <div className='profile-wrapper mt-3 menu d-flex align-items-center justify-content-center'>
                        <Link to='#'>
                            <img
                                style={{ width: '200px', borderRadius: '4px', boxShadow: "2px 2px 4px grey", position: "relative" }}
                                className={`${themeClass} `}
                                // className={darkMode === 'dark' ? 'navIcon logo' : 'logo'} 
                                src='/images/logo.svg'
                                alt='' />
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <div className="nav-menu nav-links my-2">
                            <Link to="/" className='hover-link'>HOME</Link>
                            <Link to="/about-us" className='hover-link'>ABOUT US</Link>
                            <Link to="/signin" className='hover-link'>SIGN IN</Link>
                            <Link to="/signup" className='signup-button'>SIGN UP</Link>
                        </div>
                    </div>
                </div>
            </nav>
            {/* ------------------------------------------------- */}
            {/* <nav>
                <div className="main-nav container flex">
                    <div className="company-logo">
                        <img src='./images/Logo1.jpg' alt="company logo" />
                    </div>

                    <div className="nav-links">
                        <Link to="/" className='hover-link'>HOME</Link>
                        <Link to="/signin" className='hover-link'>SIGN IN</Link>
                        <Link to="/signup" className='signup-button'>SIGN UP</Link>
                    </div>
                </div>
            </nav> */}

            {/* Banner Section */}
            {/* <Container style={{zoom:(0.8)}}> */}
            <section className="banner">
                <div className="" style={{ overflow: "hidden" }}>
                        <div className="col-md-12 px-5">
                            <div className="row">
                                <div className="mb-3 col-md-6 d-flex align-items-start justify-content-center flex-column" style={{}}>
                                    <div className="banner-container-upper" style={{ fontSize: "1.8rem", borderLeft: "10px solid #069514", padding: "0px 20px" }}>
                                        <div>PUT YOUR <span style={{ color: "#069514" }}>MONEY</span></div>
                                        <div>WHERE YOUR MOUTH IS!</div>
                                    </div>
                                    <div className="text-uppercase my-3" style={{ fontSize: "1.4rem" }}>UNLOCK THE POWER OF PREDICTIONS: TRADE ON THE OUTCOME OF FUTURE GAMES AND EVENTS</div>
                                    {/* <Link to="/signup">
                                        <button className="btn" style={{ color: "white", padding: "6px 15px", backgroundColor: "#069514",width:"350px",height:"45px"}}>Register Now</button>
                                    </Link> */}
                                    <div class="d-grid" style={{ width: "100%" }}>
                                        <Link to="/signup">
                                            <button class="btn btn-block" type="button" style={{ color: "white", padding: "6px 15px", backgroundColor: "#069514", height: "50px" ,fontWeight:500,fontSize:"20px"}}>Register Now</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-end">
                                    <img src="./images/Designer.png" alt="" style={{
                                        width: "325px",
                                        height: "416px"
                                    }} />
                                </div>
                            </div>
                        </div>
                </div>
            </section>

            {/* How it Works section */}
            <div className="row">
            <section className="banner">
                    <div className="row">
                        <div className="col-12">
                            <div className='howit container flex'>
                                <div className='howit-text flex'>
                                    <h3>How It <span style={{ color: '#069514' }}>Works</span></h3>
                                    <img src='./images/vector_line.svg' alt='how it works' />
                                </div>
                                <div className='round-container flex'>
                                    <div className='row'>
                                        <div className='col-md-4 flex justify-content-center'>
                                            <div className='round-image flex'>
                                                <img src='./images/Group59.svg' alt='how it works' />
                                                <h3 className='mt-3'>LOOK FOR A GAME OR EVENT THAT INTERESTS YOU</h3>
                                            </div>
                                        </div>
                                        <div className='col-md-4 flex justify-content-center'>
                                            <div className='round-image flex'>
                                                <img src='./images/Group60.svg' alt='how it works' />
                                                <h3 className='mt-3'>PREDICT THE OUTCOME</h3>
                                            </div>
                                        </div>
                                        <div className='col-md-4 flex justify-content-center'>
                                            <div className='round-image flex'>
                                                <img src='./images/Group61.svg' alt='how it works' />
                                                <h3 className='mt-3'>PROFIT</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            </div>

            {/* Betting Contest Section */}

            <section className='betting-section'>
                {allMarketData?.slice().reverse().map((item, index) => {
                    return (
                        <div className='container' key={index} style={{ paddingTop: "10px" }}>
                            <div className='feed-image-section-home mb-4 container'>
                                <div className='px-3 py-2 flex justify-content-between mt-4'>
                                    <div className='flex justify-content-center '>
                                        <img className='feed-profile-image-home' src={item?.createdBy?.profile_picture || "./images/photo.png"} alt="" />
                                        <p className='username'>{item?.createdBy === null ? 'User' : item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</p>
                                    </div>
                                    <div className='trending-wrapper flex justify-content-center flex-column gap-2 p-2'>
                                        <div className="flex justify-content-center gap-2">
                                            <img width="15px" src="./images/icon_clock.svg" alt="user" />
                                            {moment(item?.market?.date).format('lll')}
                                        </div>
                                        {/* <div className='like-icon-container-home flex gap-2'>
                                        <img className='clickable-icon' src='./images/icon_like.svg' alt="" />
                                        <p>2</p>
                                        <img className='clickable-icon' src='./images/Group(5).svg' alt="" />
                                        <p>12</p>
                                    </div> */}
                                    </div>
                                </div>
                                <div className='question p-2 px-3' style={{wordBreak:"break-word"}}>{item.question} </div>
                                <div style={{ width: '100%' }}>
                                    <img className="market-feed-image" src={item?.marketImage == "null" ? "/images/dummy.svg" : item?.marketImage} alt='' style={{ width: '100%',height:"500px", objectFit:"cover" }} />
                                </div>
                                <div className='feed-float-landing-page text-light flex justify-content-between px-3'>
                                    <div className='feed-float-border flex justify-content-center bg-dark'>
                                        <p className='chances-text'>
                                            <span style={{ color: '#069514', fontSize: 24 }}>{item?.yesAnswerProbability}</span>
                                        </p>
                                        <Link to='/signin' className='bet-link'>
                                            <button className='bet-btn bet-yes'>Bet YES</button>
                                        </Link>
                                        <p className='chances-text'>
                                            <span style={{ color: '#ff0000', fontSize: 24 }}>{item?.noAnswerProbability}</span>
                                        </p>
                                        <Link to='/signin' className='bet-link'>
                                            <button className='bet-btn bet-no'>Bet NO</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                })}

                {/* <div className='betting-container flex justify-content-center'>
                    <div className='feed-image-section-home mb-4 container'>
                        <div className='px-3 py-2 flex justify-content-between '>
                            <div className='flex justify-content-center '>
                                <img className='feed-profile-image-home' src='./images/Ellipse5.svg' alt="" />
                                <p className='username'>Tony Jackson</p>
                            </div>
                            <div className='trending-wrapper flex justify-content-center flex-column gap-2 p-2'>
                                <div className="flex justify-content-center gap-2">
                                    <p>Trending</p>
                                    <img src='./images/Group(4).svg' alt='' />
                                    <p>22</p>
                                </div>
                                <div className='like-icon-container-home flex gap-2'>
                                    <img className='clickable-icon' src='./images/icon_like.svg' alt="" />
                                    <p>20</p>
                                    <img className='clickable-icon' src='./images/Group(5).svg' alt="" />
                                    <p>25</p>
                                </div>
                            </div>
                        </div>
                        <div className='question p-2 px-3'>Will the Market Performance Improve Today? </div>
                        <div style={{ width: '100%' }}>
                            <img className="market-feed-image" src='./images/Rectangle17.svg' alt='' style={{ width: '105%' }} />
                        </div>
                        <div className='feed-float-home text-light flex justify-content-between px-3'>
                            <div className='feed-float-border-home flex justify-content-center bg-dark gap-2'>
                                <p className='chances-text-home'>
                                    <span style={{ color: '#069514', fontSize: 24 }}>59%</span> Chances
                                </p>
                                <button className='bet-btn-home bet-yes-home'>Bet YES</button>
                                <button className='bet-btn-home bet-no-home'>Bet NO</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='betting-container flex justify-content-center'>
                    <div className='feed-image-section-home mb-4 container'>
                        <div className='px-3 py-2 flex justify-content-between '>
                            <div className='flex justify-content-center '>
                                <img className='feed-profile-image-home' src='./images/Ellipse5(1).svg' alt="" />
                                <p className='username'>Brian T Edwards</p>
                            </div>
                            <div className='trending-wrapper flex justify-content-center flex-column gap-2 p-2'>
                                <div className="flex justify-content-center gap-2">
                                    <p>Trending</p>
                                    <img src='./images/Group(4).svg' alt='' />
                                    <p>11</p>
                                </div>
                                <div className='like-icon-container-home flex gap-2'>
                                    <img className='clickable-icon' src='./images/icon_like.svg' alt="" />
                                    <p>2</p>
                                    <img className='clickable-icon' src='./images/Group(5).svg' alt="" />
                                    <p>12</p>
                                </div>
                            </div>
                        </div>
                        <div className='question p-2 px-3'>Will the FC Barcelona win the 2023 NHL playoffs? </div>
                        <div style={{ width: '100%' }}>
                            <img className="market-feed-image" src='./images/Rectangle17.svg' alt='' style={{ width: '105%' }} />
                        </div>
                        <div className='feed-float-home text-light flex justify-content-between px-3'>
                            <div className='feed-float-border-home flex justify-content-center bg-dark gap-2'>
                                <p className='chances-text-home'>
                                    <span style={{ color: '#069514', fontSize: 24 }}>24%</span> Chances
                                </p>
                                <button className='bet-btn-home bet-yes-home'>Bet YES</button>
                                <button className='bet-btn-home bet-no-home'>Bet NO</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='betting-container flex justify-content-center'>
                    <div className='feed-image-section-home mb-4 container'>
                        <div className='px-3 py-2 flex justify-content-between '>
                            <div className='flex justify-content-center '>
                                <img className='feed-profile-image-home' src='./images/Ellipse5.svg' alt="" />
                                <p className='username'>Tony Jackson</p>
                            </div>
                            <div className='trending-wrapper flex justify-content-center flex-column gap-2 p-2'>
                                <div className="flex justify-content-center gap-2">
                                    <p>Trending</p>
                                    <img src='./images/Group(4).svg' alt='' />
                                    <p>22</p>
                                </div>
                                <div className='like-icon-container-home flex gap-2'>
                                    <img className='clickable-icon' src='./images/icon_like.svg' alt="" />
                                    <p>20</p>
                                    <img className='clickable-icon' src='./images/Group(5).svg' alt="" />
                                    <p>25</p>
                                </div>
                            </div>
                        </div>
                        <div className='question p-2 px-3'>Will the Market Performance Improve Today? </div>
                        <div style={{ width: '100%' }}>
                            <img className="market-feed-image" src='./images/Rectangle17.svg' alt='' style={{ width: '105%' }} />
                        </div>
                        <div className='feed-float-home text-light flex justify-content-between px-3'>
                            <div className='feed-float-border-home flex justify-content-center bg-dark gap-2'>
                                <p className='chances-text-home'>
                                    <span style={{ color: '#069514', fontSize: 24 }}>59%</span> Chances
                                </p>
                                <button className='bet-btn-home bet-yes-home'>Bet YES</button>
                                <button className='bet-btn-home bet-no-home'>Bet NO</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            </Container>
            <div className="flex" style={{ backgroundColor: "black" }}>
                <div className="row container py-3">
                    <div className="col-lg-4 flex justify-content-center mb-3">
                        <div className="flex">
                            <div className="footer-logo">
                                {/* <div className="company-logo">
                                    <img style={{ borderRadius: "20px", filter: "invert(100%) sepia(4%) saturate(10%) hue-rotate(194deg) brightness(102%) contrast(102%)" }}
                                        src="./images/Logo1.svg"
                                        alt="company logo" />
                                </div> */}
                                <div className='menu d-flex align-items-center justify-content-center'>
                                    <Link to='#'>
                                        <img
                                            style={{ width: '200px', borderRadius: "20px", filter: "invert(100%) sepia(4%) saturate(10%) hue-rotate(194deg) brightness(102%) contrast(102%)" }}
                                            className={theme === "Dark" ? "navIcon logo" : "logo"}
                                            // className={darkMode === 'dark' ? 'navIcon logo' : 'logo'} 
                                            src='/images/logo.svg'
                                            alt='' />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container col-lg-4 ">
                        <div className="">
                            <h6 className="footer-heading text-uppercase text-white">Help</h6>
                            <ul className="list-unstyled footer-link mt-4">
                                <li><Link to="/about-us">ABOUT US</Link></li>
                                <li><Link to='/privacy-policy'>PRIVACY POLICY</Link></li>
                                {/* <li><Link to='/faq'>FAQ</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="">
                            <h6 className="footer-heading text-uppercase text-white">Reach Us</h6>
                            <div className="mt-5">
                                <div className="social-media-logo flex">
                                    <a href="https://www.instagram.com/smarter__bet" target="_blank" rel="noreferrer">
                                        <img src="./images/Instagram.svg" alt="Instagram" />
                                    </a>

                                    <a href="https://www.facebook.com/profile.php?id=61553301357888" target="_blank" rel="noreferrer">
                                        <img src="./images/Facebook.svg" alt="Facebook" />
                                    </a>

                                    <a href="https://twitter.com/smarter__bet" target="_blank" rel="noreferrer">
                                        <img src="./images/twitterrr.svg" alt="twitter" style={{ width: "45px", height: "45px" }} />
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default Home