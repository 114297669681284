import React from 'react'
import { Link } from 'react-router-dom'
import LeftSidePanel from '../Components/LeftSidePanel'
import { useContext } from 'react'
import { ThemeContext } from '../Context/ThemeContext';
import { useState } from 'react';
import './styles/Contactus.css';
import { axiosInstance } from '../network/config/adapter';
import { ApiEndpoints } from '../network/config/endpoints';
import { ToastContainer, toast } from 'react-toastify';

const Contactus = () => {

    const { themeClass, themeInput } = useContext(ThemeContext);
    const storedToken = localStorage.getItem('Oddplus_Token')

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = formData
        axiosInstance
            .post(ApiEndpoints.users.contact, payload)
            .then((response) => {
                const notify = () => {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                })
            })
            .catch((error) => {
                const notify = () => {
                    toast.error(error.response.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                };
                notify();
            })
            .finally(() => {
                // setLoading(false);
            })

    };

    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-start">
                {/* ---------------------Column 1--------------------- */}

                {storedToken === null ? <></>
                    :
                    <LeftSidePanel />
                }

                {/* ---------------------Column 2--------------------- */}
                <div className="col-md-9">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mt-5 mb-4 text-justify">
                                    <div className="">
                                        <nav className="navbar navbar-expand-md mt-3 mb-1 flex justify-content-start">
                                        {storedToken === null ?
                                            <Link to='/signin'>
                                                <button className='mr-4'
                                                    style={{
                                                        border: 'none',
                                                        width: 35,
                                                        height: 35,
                                                        borderRadius: 30
                                                    }}>
                                                    <img src='./images/icon_back.svg' alt="back" />
                                                </button>
                                            </Link>
                                            :
                                             <Link to='/dashboard-sitemap'>
                                                <button className='mr-4'
                                                    style={{
                                                        border: 'none',
                                                        width: 35,
                                                        height: 35,
                                                        borderRadius: 30
                                                    }}>
                                                    <img src='./images/icon_back.svg' alt="back" />
                                                </button>
                                            </Link>
}
                                            {/* <h3 className="font-weight-bold m-0">Get In touch </h3> */}
                                        </nav>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="d-flex align-items-center justify-content-between gap-4" id="contact-wrapper">
                                            <div className="get-in-touch-left">
                                                <h1 className="font-weight-bold mb-4">Get In Touch </h1>
                                                <div className="mb-4">
                                                    <div className="form-outline">
                                                        <label className="label-color" htmlFor="form3Example1m">Name</label>
                                                        <input
                                                            style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                            type="text"
                                                            name="name"
                                                            id="form3Example1m"
                                                            className={`${themeInput} form-control form-control-sm shadow-none`}
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <div className="form-outline">
                                                        <label className="label-color" htmlFor="form3Example97">Email Address</label>
                                                        <input
                                                            style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                            type="email"
                                                            id="form3Example97"
                                                            name="email"
                                                            className={`${themeInput} form-control form-control-sm shadow-none`}
                                                            // value={email}
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="label-color" htmlFor="form3Example1n">Message</label>
                                                    <textarea
                                                        className={`${themeInput} form-control form-control-sm shadow-none`}
                                                        id="exampleFormControlTextarea1"
                                                        rows="3"
                                                        maxLength="1200"
                                                        name="message"
                                                        style={{ outline: 0, borderWidth: '0 0 1px' }}
                                                        value={formData.message}
                                                        onChange={handleInputChange}
                                                    >
                                                    </textarea>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            border: 'none',
                                                            color: 'white',
                                                            backgroundColor: '#069514',
                                                            padding: '10px 15px',
                                                            borderRadius: '50px',
                                                        }}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center my-5 px-2"
                                                style={{
                                                    border: "1px solid #8080805c",
                                                    // height: "50vh",
                                                    borderRadius: "16px",
                                                }}>
                                                <div className="form-outline mt-2" >
                                                    <h3 >Contact Details</h3>
                                                    <div className="col-md-12 mt-5">
                                                        <div className="form-outline mt-2">
                                                            <img className='mr-4 contact-icon' src='./images/phone.svg' alt='' />
                                                            <label className="label-color" htmlFor="form3Example1m">Call</label>
                                                            <div className='contact-input-wrapper border-bottom pb-3'>
                                                                <p>2356568998</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-outline mt-2">
                                                            <img className='mr-4' src='./images/icon_email.svg' alt='' />
                                                            <label className="label-color" htmlFor="form3Example1m">Mail</label>
                                                            <div className='contact-input-wrapper border-bottom pb-3'>
                                                                <p>nicolaskhavov@smarter-bet.com</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-outline mt-2">
                                                            <img className='mr-4' src='./images/carbon_location.svg' alt='' />
                                                            <label className="label-color" htmlFor="form3Example1m">Location</label>
                                                            <div className='contact-input-wrapper border-bottom pb-3'>
                                                                <div>9011 Livingston Rd, Fort Washington, US</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <div className="form-outline mt-2">
                                                            <label className="label-color" htmlFor="form3Example1m">Follow Us on</label>
                                                            <div className='contact-social-img'>
                                                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>

                                                                    <a href="https://www.facebook.com/profile.php?id=61553301357888" target="_blank" rel="noreferrer">
                                                                        <img src='./images/facebook_2.svg' alt='' />
                                                                    </a>
                                                                    <a href="https://twitter.com/smarter__bet" target="_blank" rel="noreferrer">
                                                                        <img src="./images/twitter.svg" alt="twitter" />
                                                                    </a>
                                                                    <a href="https://www.instagram.com/smarter__bet" target="_blank" rel="noreferrer">
                                                                        <img src='./images/ri_instagram-fill.svg' alt='' />
                                                                    </a>
                                                                    <img src='./images/entypo-social_linkedin.svg' alt='' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Contactus