import React from 'react'
import { Link } from 'react-router-dom'

const MarketCards = (props) => {

    const maxLength = 80; // Maximum length of the truncated string
    const { market } = props;
    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.substr(0, maxLength) + '...';
        }
        return str;
    };

    return (
        <Link to={`/dashboard-market-detail/${props._id}`}>
            <div className='bet-list d-flex justify-content-between align-items-center mb-3 p-2'>
                <div className='d-flex align-items-center gap-4'>
                    <img className='market-profile-img' src={props.market.createdBy?.profile_picture || './images/photo.png'} alt='' />
                    {/* <img className='market-profile-img' src={`${props.market.createdBy.profile_picture}` || `./images/profile_image.svg`} alt='' /> */}
                    <p className='pr-4' style={{wordBreak:"break-word"}}>{truncateString(market.question, maxLength)}</p>
                    {/* <p className='pr-4'>{market.question}</p> */}
                </div>

                <div className=''>
                    <div className='d-flex align-items-center gap-2'>
                        {/* <div className=''>
                            <div>5%</div>
                        </div>
                        <img style={{ width: '14px' }} src='./images/Vector(9).svg' alt='' />24 */}
                        <button className='market-bet-btn'>Bet</button>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default MarketCards